import React, { createContext, useState, useContext } from "react";

// Create a context
const LabelContext = createContext();

// Create a provider component
export const LabelProvider = ({ children }) => {
  const [labelDataa, setLabelDataa] = useState(null);
  const [returnBooks, setReturnBooks] = useState(null);

  return (
    <LabelContext.Provider
      value={{ labelDataa, setLabelDataa, returnBooks, setReturnBooks }}
    >
      {children}
    </LabelContext.Provider>
  );
};

// Custom hook to use the context
export const useLabelContext = () => {
  return useContext(LabelContext);
};
