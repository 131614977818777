import React, { useState, useRef, useEffect } from "react";
import { Select } from "antd";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { API_BASE_LB_URL } from "../Config";
import Swal from "sweetalert2";
import { useLabelContext } from "../context";
import axios from "axios";

const BookTitles = (props) => {
  const [fileName, setFileName] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [rowdata, setRowdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [bookType, setBookType] = useState([]);
  const [getData, setGetData] = useState([]);
  const [booklabelsData, setBooklabelsData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [course, setCourse] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [finalOrgId, setFinalOrgId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const [NamesearchText, setNameSearchText] = useState("");
  const [BooksearchText, setBookSearchText] = useState("");
  const [data, setData] = useState([]);
  const [NameFilteredData, setNameFilteredData] = useState([]);
  const [Bookdata, setBookData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [BookfilteredData, setBookFilteredData] = useState([]);
  const [load, setLoad] = useState(true);
  const [errMsg, setErrMsg] = useState("Loading...");
  const { setLabelData } = useLabelContext();
  const [activeClass, setActiveClass] = useState("");
  const [excloading, setExcLoading] = useState(false);
  const [uploadText, setUploadText] = useState("Upload");
  const [excdetails, setExcDetails] = useState({
    BookCatId: "NULL",
    BookTypeId: "NULL",
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [bookDetails, setBookDetails] = useState({
    book_name: "",
    author: "",
    category: "NULL",
    book_type: "NULL",
    publisher: "",
    purchase_date: "",
    edition: "NULL",
    price: "NULL",
    quantity: "NULL",
  });
  const excdata = [
    [
      "Book Name",
      "Author Name",
      "Publisher",
      "Edition",
      "Quantity",
      "Price",
      "Purchased on",
    ],

    // Add more rows as needed
  ];
  const navigate = useNavigate();
  const courseOptions = course.map((course) => ({
    value: course.Id,
    label: course.Name,
  }));

  // const typeOptions = bookType.map((type) => ({
  //   value: type.Id,
  //   label: type.Name,
  // }));

  const typeOptions = bookType?.map((type) => ({
    value: type.Id,
    label: type.Name,
  }));

  const getByLabel = (item) => {
    console.log(item);
  };
  //ONCLICK EDIT IN ACTIONS
  const handleEdit = (item) => {
    setRowdata(item);
    console.log(item);
  };
  const handleBookTItlesSearch = async () => {
    if (!finalOrgId) {
      console.error("Organization ID is not set.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}bookmaster/getallbookmaster?OrgId=${finalOrgId}&SearchText='${searchInput}'`
      );
      if (!response.ok) {
        setErrMsg("No Records Found");
        setGetData([]);
      }
      const result = await response.json();
      const limitedResult = result.slice(0, 15);
      setGetData(limitedResult);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBookLabelsSearch = async () => {
    if (!finalOrgId) {
      console.error("Organization ID is not set.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}bookmaster/getallbookmaster?OrgId=${finalOrgId}&SearchText='${searchInput}'`
      );
      if (!response.ok) {
        setErrMsg("No Records Found");
        setBooklabelsData([]);
      }
      const result = await response.json();
      const limitedResult = result.slice(0, 15);
      setBooklabelsData(limitedResult);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddBook = async (e) => {
    e.preventDefault();
    console.log(bookDetails);
    if (bookDetails.book_name === "") {
      alert("Enter Book Name");
    } else if (bookDetails.category === "NULL") {
      alert("Select Category");
    } else if (bookDetails.book_type === "NULL") {
      alert("Select Book Type");
    } else {
      const requestData = {
        Type: "insert",
        OrgId: finalOrgId,
        BookCatId: bookDetails.category,
        BookTypeId: bookDetails.book_type,
        Author: bookDetails.author,
        Name: bookDetails.book_name,
        Publisher: bookDetails.publisher,
        CreatedBy: updatedId,
        InventoryCreatedBy: updatedId,
        UpdatedBy: updatedId,
        Price: bookDetails.price ? bookDetails.price : "NULL",
        PurchasedOn: bookDetails.purchase_date,
        Quantity: bookDetails.quantity ? bookDetails.quantity : "NULL",
        Edition: bookDetails.edition,
        IsLabled: 0,
      };

      console.log("Request Body:", JSON.stringify(requestData, null, 2));

      try {
        // Make a POST request to update data
        const response = await fetch(
          `
        ${API_BASE_LB_URL}bookmaster/SaveBookDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );
        console.log(bookDetails);

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`
          HTTP error! status: ${response.status}, message: ${errorText}`);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "success.",
            confirmButtonText: "OK",
          }).then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        }

        // Clear the form after successful update
        setBookDetails({
          book_name: "",
          author: "",
          category: "",
          book_type: "",
          publisher: "",
          purchase_date: "",
          edition: "",
          price: "",
          quantity: "",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setBookDetails({
      book_name: "",
      author: "",
      category: "NULL",
      book_type: "NULL",
      publisher: "",
      purchase_date: "",
      edition: "NULL",
      price: "NULL",
      quantity: "NULL",
    });
  };

  const handleEditBook = async (e) => {
    e.preventDefault();
    console.log(rowdata);
    console.log("ID :", rowdata);
    const requestData = {
      Type: "update",
      BookInventoryId: rowdata.BookInventoryId,
      BookId: rowdata.BookId,
      OrgId: finalOrgId,
      BookCatId: rowdata.BookCatId,
      CategoryName: rowdata.BookCatId,
      BookTypeId: rowdata.BookTypeId,
      Author: "" || rowdata.Author,
      Name: "" || rowdata.Name,
      Publisher: "" || rowdata.Publisher,
      UpdatedBy: updatedId,
      Price: rowdata.Price || "NULL",
      PurchasedOn: rowdata.PurchasedDate ? rowdata.PurchasedDate : "",
      Quantity: rowdata.Quantity || "NULL",
      Edition: rowdata.Edition ? rowdata.Edition : "NULL",
    };
    console.log("Request Body:", JSON.stringify(requestData, null, 2));
    try {
      // Prepare the data you want to send in the request body

      // Make a POST request to update data
      const response = await fetch(
        `
        ${API_BASE_LB_URL}bookmaster/SaveBookDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      const responseBody = await response.json();
      console.log(responseBody);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.reload();
          }
        });
      }

      // Clear the form after successful update (if applicable)
      // e.g., setRowData({}); // Assuming you have a state hook for rowData
      console.log(rowdata);
      console.log("Update successful");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  const handleDeleteBook = async (item) => {
    console.log(item);
    console.log("DELETE TRIGGERED");

    try {
      // Make a POST request to update data
      const response = await fetch(
        `${API_BASE_LB_URL}bookmaster/DELTInventory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            BookId: item.BookId,
            InventoryId: item.BookInventoryId,
            UpdatedBy: updatedId,
          }),
        }
      );
      console.log(response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        });
        // .then((res) => {
        //   if (res.isConfirmed) {
        //     window.location.reload();
        //   }
        // });
        const updatedData = getData.filter(
          (each) => each.BookInventoryId !== item.BookInventoryId
        );
        setGetData(updatedData);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  const handleAddInventory = async (item) => {
    setRowdata(item);
    console.log(item);
  };

  const handleAddBookInventory = async (e) => {
    e.preventDefault();

    if (rowdata.PurchasedDate === "") {
      alert("Select Date");
    } else if (rowdata.Edition === "") {
      alert("Enter Edition");
    } else {
      const requestData = {
        BookId: rowdata.BookId,
        Price: rowdata.Price,
        PurchasedOn: rowdata.PurchasedDate,
        Quantity: rowdata.Quantity,
        Edition: rowdata.Edition,
        CreatedBy: updatedId,
        IsActive: 1,
      };

      console.log("Request Body:", JSON.stringify(requestData, null, 2));
      try {
        // Make a POST request to update data
        const response = await fetch(
          `
        ${API_BASE_LB_URL}bookmaster/AddInventory`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "success.",
            confirmButtonText: "OK",
          }).then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        }

        // Clear the form after successful update
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    }
  };
  const baseDate = new Date(1900, 0, 0); // January 1, 1900

  // Handler for file input change
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      // Read the file using FileReader
      setFileName(uploadedFile.name);
      // setStatus("Uploading");
      const reader = new FileReader();
      reader.onload = (e) => {
        // Parse the file data with xlsx
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the data is in the first sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });

        const formatDateToDDMMYYYY = (dateString) => {
          const [year, month, day] = dateString.split("-").map(Number);
          return `${month.toString().padStart(2, "0")}/${day
            .toString()
            .padStart(2, "0")}/${year}`;
        };

        const formattedData = jsonData?.map((item) => {
          const serialDate = item["D.O.B"];
          if (isNaN(serialDate)) {
            // console.error(`Invalid serial date: ${serialDate}`);
            return {
              first_name: item["First Name"],
              last_name: item["Last Name"],
              address: item.Address,
              branch: item.Branch,
              course: item.Course,
              dob: serialDate, // Handle invalid dates gracefully
              gender: item.Gender,
              hall_ticket: item["Hall ticket"],
              mobile_no: item["Mobile Number"],
            };
          }
          const date = new Date(
            baseDate.getTime() + serialDate * 24 * 60 * 60 * 1000
          );
          const isoDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
          const formattedDate = formatDateToDDMMYYYY(isoDate); // Convert to DD/MM/YYYY
          return {
            first_name: item["First Name"],
            last_name: item["Last Name"],
            address: item.Address,
            branch: item.Branch,
            course: item.Course,
            dob: formattedDate,
            gender: item.Gender,
            hall_ticket: item["Hall ticket"],
            mobile_no: item["Mobile Number"],
          };
        });

        // STARTING CODE FOR CHUNK EXCEL
        const chunkSize = 50;
        if (formattedData.length > chunkSize) {
          alert(`Please enter not more than ${chunkSize} records.`);
          return;
        }
        // let index = 0;
        // const totalChunks = Math.ceil(formattedData.length / chunkSize);
        const totalChunks = 1;
        let accumulatedData = [];

        const processChunk = async (chunkIndex) => {
          if (chunkIndex >= totalChunks) {
            // setStatus("Completed");
            setExcelData(accumulatedData);
            return;
          }

          const startIndex = chunkIndex * chunkSize;
          const endIndex = Math.min(
            startIndex + chunkSize,
            formattedData.length
          );
          const chunk = formattedData.slice(startIndex, endIndex);

          // Simulate updating the chunk
          console.log(
            `Processing chunk ${chunkIndex + 1}/${totalChunks}`,
            chunk
          );

          // Accumulate processed data
          accumulatedData = accumulatedData.concat(chunk);

          // // Update the index
          // index += 1;

          // Delay to simulate async processing

          // await new Promise((resolve) => setTimeout(resolve, 1000));

          // Process next chunk
          processChunk(chunkIndex + 1);
        };

        // END CODE FOR CHUNK EXCEL

        processChunk(0);
      };
      reader.readAsArrayBuffer(uploadedFile);
      // console.log("Final setExcelData : ", excelData);
    }
  };

  const generateExcel = () => {
    // Create a new workbook
    const ws = XLSX.utils.aoa_to_sheet(excdata);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate buffer and save the file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "books.xlsx");
  };

  const handleExcelUpload = (e) => {
    e.preventDefault();
    console.log(excdetails);

    const file = document.getElementById("fileinput").files[0];
    if (excdetails.BookCatId === "NULL" || excdetails.BookTypeId === "NULL") {
      alert("Select Category and Book Type");
    } else {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });

          const formatDateToDDMMYYYY = (dateString) => {
            const [year, month, day] = dateString.split("-").map(Number);
            return `${year}-${month.toString().padStart(2, "0")}-${day
              .toString()
              .padStart(2, "0")}`;
          };

          const formattedData = jsonData.map((item) => {
            const serialDate =
              item["Purchased on"] === undefined ? "" : item["Purchased on"];
            if (isNaN(serialDate)) {
              // console.error(`Invalid serial date: ${serialDate}`);
              return {
                book_name: item["Book Name"] ? item["Book Name"] : "",
                author_name: item["Author Name"] ? item["Author Name"] : "",
                Publisher: item.Publisher ? item.Publisher : "",
                BookCatId: "" || excdetails.BookCatId,
                BookTypeId: "" || excdetails.BookTypeId,
                Edition: item.Edition ? item.Edition : "NULL",
                Price: item.Price ? item.Price : "NULL",
                Quantity: item.Quantity ? item.Quantity : "NULl",
                purchased_on: "" || serialDate, // Handle invalid dates gracefully
              };
            }
            const date = new Date(
              baseDate.getTime() + serialDate * 24 * 60 * 60 * 1000
            );
            const isoDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            const formattedDate = formatDateToDDMMYYYY(isoDate); // Convert to DD/MM/YYYY
            return {
              book_name: item["Book Name"] ? item["Book Name"] : "",
              author_name: item["Author Name"] ? item["Author Name"] : "",
              Publisher: item.Publisher ? item.Publisher : "",
              BookCatId: "" || excdetails.BookCatId,
              BookTypeId: "" || excdetails.BookTypeId,
              Edition: item.Edition ? item.Edition : "NULL",
              Price: item.Price ? item.Price : "NULl",
              Quantity: item.Quantity ? item.Quantity : "NULL",
              purchased_on: formattedDate === "1899-12-30" ? "" : formattedDate,
              // purchased_on: "" || formattedDate,
            };
          });

          // STARTING CODE FOR CHUNK EXCEL
          const chunkSize = 50;
          if (formattedData.length > chunkSize) {
            alert(`Please enter not more than ${chunkSize} records.`);
            return;
          }
          // let index = 0;
          // const totalChunks = Math.ceil(formattedData.length / chunkSize);
          const totalChunks = 1;
          let accumulatedData = [];

          const processChunk = async (chunkIndex) => {
            if (chunkIndex >= totalChunks) {
              // setStatus("Completed");
              setExcelData(accumulatedData);
              return;
            }

            const startIndex = chunkIndex * chunkSize;
            const endIndex = Math.min(
              startIndex + chunkSize,
              formattedData.length
            );
            const chunk = formattedData.slice(startIndex, endIndex);

            // Simulate updating the chunk
            console.log(
              `Processing chunk ${chunkIndex + 1}/${totalChunks}`,
              chunk
            );

            // Accumulate processed data
            accumulatedData = accumulatedData.concat(chunk);

            // // Update the index
            // index += 1;

            // Delay to simulate async processing

            await new Promise((resolve) => setTimeout(resolve, 1000));

            // Process next chunk
            processChunk(chunkIndex + 1);
          };

          processChunk(0);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  // Handler for button click to open the file picker
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  };

  //FORMATTING DATE IN EDIT STUDENT DETAILS
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    // Ensure the date is valid
    if (isNaN(date.getTime())) return "";

    // Format to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          setUserDetails(parsedUserDetails);
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          setFinalOrgId(orgId);
          setUpdatedId(UpdatedById);
          setLoad(false);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (finalOrgId) {
      setLoading(true);

      const fetchData = async () => {
        if (excelData.length === 0) return;
        let successfulRecords = 0;
        let errorOccurred = false;

        setExcLoading(true);
        setUploadText("Uploading...");

        try {
          for (let i = 0; i < excelData.length; i++) {
            const item = excelData[i];

            try {
              const response = await fetch(
                `${API_BASE_LB_URL}bookmaster/SaveBookDetails`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    Type: "insert",
                    OrgId: finalOrgId,
                    BookCatId: item.BookCatId,
                    BookTypeId: item.BookTypeId,
                    Author: item.author_name,
                    Name: item.book_name,
                    Publisher: item.Publisher,
                    CreatedBy: updatedId,
                    InventoryCreatedBy: updatedId,
                    UpdatedBy: updatedId,
                    Price: item.Price,
                    PurchasedOn: item.purchased_on,
                    Quantity: item.Quantity,
                    Edition: item.Edition,
                    IsLabled: 0,
                  }),
                }
              );

              const responseText = await response.json();
              // console.log(response);
              // console.log("Response Text:", responseText);

              if (!response.ok) {
                errorOccurred = true; // Mark as error if any record fails

                Swal.fire({
                  icon: "error",
                  title: "Oops!",
                  text: `Something went wrong with the request. ${successfulRecords} record(s) uploaded. Check the record from row ${
                    i + 2
                  }.`,
                  confirmButtonText: "Retry",
                });
                break;
              } else {
                successfulRecords += 1;
              }
            } catch (innerEror) {
              errorOccurred = true;
              Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Something went wrong with the request. Please try again.",
                confirmButtonText: "Retry",
              });
              break; // Exit the loop to stop processing
            }
          }
          if (!errorOccurred && successfulRecords > 0) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "All records processed successfully.",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "Something went wrong. Please try again.",
            confirmButtonText: "Retry",
          });
        } finally {
          setExcelData([]);
          setExcLoading(false);
          setUploadText("Upload");
          setExcDetails({
            BookCatId: "NULL",
            BookTypeId: "NULL",
          });
          const fileInput = document.getElementById("fileinput");
          if (fileInput) {
            fileInput.value = ""; // Clear the input field
          }
        }
      };
      const getBooksFromDB = async () => {
        if (!finalOrgId) {
          console.error("OrgId is undefined in getBooksFromDB");
          return;
        }
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookmaster/getallbookmaster?OrgId=${finalOrgId}&SearchText=NULL`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();
          const limitedResult = result.slice(0, 20);
          sessionStorage.setItem("bookData", JSON.stringify(result));
          setGetData(limitedResult);
          setBooklabelsData(limitedResult);
        } catch (error) {
          console.error("Fetch error:", error);
        } finally {
          setLoading(false);
        }
      };
      const getCourse = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookmaster/getbookcategories?OrgId=${finalOrgId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();
          setCourse(result);
        } catch (error) {
          console.log(error);
        }
      };
      const getBookType = async () => {
        if (finalOrgId) {
          try {
            const response = await fetch(`
            ${API_BASE_LB_URL}bookmaster/GetBookTypes?OrgId=${finalOrgId}`);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const result = await response.json();
            setBookType(result.ResultData);
          } catch (error) {
            console.log(error);
          }
        }
      };

      getBooksFromDB();
      getCourse();
      getBookType();
      fetchData();
    }
  }, [excelData, finalOrgId, updatedId]);

  const getGenerateLabelFromDB = async (item) => {
    // const bookData = JSON.parse(sessionStorage.getItem("bookData"));

    // const selectedBook = bookData.find(
    //   (book) => book.BookInventoryId === selectedBookId
    // );
    // if (!selectedBook) {
    //   console.error("Book not found with the given ID.");
    //   return;
    // }

    const bookInventoryId = item.BookInventoryId;
    if (!bookInventoryId) {
      console.error("BookInventoryId not found for the selected book.");
      return;
    }
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}booklabel/getLabelsByInventoryId?InventoryId=${bookInventoryId}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.length === 1) {
        const newItems = Array.from(
          { length: result[0].Quantity },
          (_, index) => ({
            ...result[0],
            IsActive: true,
          })
        );
        console.log("data passed", newItems);
        setLabelData(newItems);
      } else {
        setLabelData(result);
      }

      console.log(result);
    } catch (error) {
      console.log("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setNameSearchText(event.target.value);
  };

  const handleBooksearchChange = (event) => {
    setBookSearchText(event.target.value);
  };

  //issue books

  // Fetch students based on search text
  const handleNameSearchClick = () => {
    setLoading(true);
    const url = `${API_BASE_LB_URL}bookrent/getstudentsddl?OrgId=${finalOrgId}&SearchText=NULL`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        const filterbyname = data.filter(
          (item) =>
            (item.FullName &&
              item.FullName.toLowerCase().includes(
                NamesearchText.toLowerCase()
              )) ||
            (item.HallTicketNo &&
              item.HallTicketNo.toLowerCase().includes(
                NamesearchText.toLowerCase()
              ))
        );
        setNameFilteredData(filterbyname);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Fetch books based on search text
  const handleBookSearchClick = () => {
    setLoading(true);
    const url = `${API_BASE_LB_URL}bookrent/getbooksddl?OrgId=${finalOrgId}&SearchText=NULL`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBookData(data);
        const filterbybook = data.filter(
          (item) =>
            item.BookName.toLowerCase().includes(
              BooksearchText.toLowerCase()
            ) ||
            item.BookLabel.toLowerCase().includes(BooksearchText.toLowerCase())
        );
        setBookFilteredData(filterbybook);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Handle student selection
  const handlePersonSelect = (person) => {
    setSelectedPerson((prevSelected) => {
      if (prevSelected.includes(person.regid)) {
        // If already selected, deselect by filtering out the regid
        return prevSelected.filter((id) => id !== person.regid);
      } else {
        // If not selected, add the regid to the array
        return [...prevSelected, person.regid];
      }
    });
  };
  // Handle book issuance
  const handleIssue = async (book) => {
    if (!selectedPerson) {
      alert("Please select a person first.");
      return;
    }
    const note = document.querySelector(`#note-${book.bookid}`).value;
    const dueDate = document.querySelector(`#dueDate-${book.bookid}`).value;

    const postData = {
      RegId: 1190,
      personId: selectedPerson.RegId,
      bookId: book.BookId,
      bookName: book.BookName,
      accessNo: book.BookLabel,
      bookLabelId: book.BookLabelId,
      dueDate: dueDate,
      OrgId: finalOrgId,
      TransactionId: 4501,
      IssueDate: "2024-08-23T10:00:00",
      IssuedBy: 101,
      IssueComment: note,
      Status: 1,
      CreatedBy: 101,
      IsActive: 1,
    };
    console.log("Sending data to server:", postData);

    try {
      const response = await axios.post(
        `${API_BASE_LB_URL}bookrent/IssueBook`,
        postData
      );
      if (response.status === 200) {
        alert("Book issued successfully!");
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error issuing book:", error);
      alert("Failed to issue the book.");
    }

    // Optional: Store issued data in local storage
    const issuedData = {
      person: selectedPerson,
      book: book,
    };
    console.log("Issued Data:", issuedData);
    localStorage.setItem("issuedData", JSON.stringify(issuedData));
  };
  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-3" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Book Titles
                  </h1>
                </div>
                <button
                  type="button"
                  className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                  // data-bs-toggle="modal"
                  // data-bs-target="#kt_modal_add_customer"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  aria-controls="offcanvasExample"
                >
                  Add Book
                  {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                </button>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          data-kt-customer-table-filter="search"
                          className="form-control form-control-solid w-220px ps-13"
                          placeholder="Search  Name or Author"
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-2 w-60px"
                          onClick={handleBookTItlesSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasupload"
                          aria-controls="offcanvasUpload"
                        >
                          <i className="ki-duotone ki-exit-down fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Import
                        </button>

                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          onClick={generateExcel}
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Excel Template
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_add_customer"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasExample"
                          aria-controls="offcanvasExample"
                        >
                          Add Book
                        </button> */}
                        <style>
                          {`
                                .offcanvas.custom-offcanvas {
                                  width: 400px !important; /* Adjust this as needed */
                               }

                                @media (max-width: 576px) {
                                  .offcanvas.custom-offcanvas {
                                    width: 70% !important; /* Adjust the percentage as needed */
                                  }
                                }
                          `}
                        </style>
                        {/* OFFCANVAS FOR ADD BOOK  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvasExample"
                          aria-labelledby="offcanvasExampleLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title"
                              id="offcanvasExampleLabel"
                            >
                              Add Book
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form className="row p-7">
                            <div className="col-12 col-md-6 mb-5 fv-row">
                              <label
                                htmlFor="bookname"
                                className="required form-label mb-3"
                              >
                                Book Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="bookname"
                                name="book_name"
                                value={bookDetails.book_name}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-6 mb-5 fv-row">
                              <label
                                htmlFor="author"
                                className="required form-label mb-3"
                              >
                                Author
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="author"
                                name="author"
                                value={bookDetails.author}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="category"
                                className=" form-label mb-3"
                              >
                                Cateogry
                              </label>
                              <Select
                                // style={{ width: 180 }}
                                placeholder="Choose Category"
                                id="category"
                                value={
                                  bookDetails.category === "NULL"
                                    ? null
                                    : bookDetails.category
                                }
                                onChange={(value) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    category: value,
                                  }));
                                }}
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="book_type"
                                className=" form-label mb-3"
                              >
                                Book type
                              </label>
                              <Select
                                // style={{ width: 180 }}
                                placeholder="Book Type"
                                id="book_type"
                                value={
                                  bookDetails.book_type === "NULL"
                                    ? null
                                    : bookDetails.book_type
                                }
                                onChange={(value) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    book_type: value,
                                  }));
                                }}
                                options={typeOptions}
                              />
                            </div>

                            <div className="col-12 col-md-6 mb-5 d-flex flex-column">
                              <label
                                htmlFor="publisher"
                                className="required form-label mb-3"
                              >
                                Publisher
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="publisher"
                                name="publisher"
                                value={bookDetails.publisher}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-6 mb-5 d-flex flex-column">
                              <label
                                htmlFor="purchase_date"
                                className="required form-label mb-3"
                              >
                                Purchased Date
                              </label>
                              <input
                                type="date"
                                style={{ width: 180 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="purchase_date"
                                name="purchase_date"
                                value={bookDetails.purchase_date}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="edition"
                                className=" form-label mb-3"
                              >
                                Edition
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="edition"
                                name="edition"
                                value={bookDetails.edition}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="price"
                                className=" form-label mb-3"
                              >
                                Price
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="price"
                                name="price"
                                value={bookDetails.price || ""}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: Number(e.target.value),
                                  }));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="quantity"
                                className=" form-label mb-3"
                              >
                                Quantity
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="quantity"
                                name="quantity"
                                value={bookDetails.quantity || ""}
                                onChange={(e) => {
                                  setBookDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: Number(e.target.value),
                                  }));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddBook}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger ms-2"
                                data-bs-dismiss="offcanvas"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>

                        {/* OFFCANVAS FOR EDIT BOOK  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvaseditbook"
                          aria-labelledby="offcanvasExampleLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title"
                              id="offcanvaseditbook"
                            >
                              Edit Book
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form className="row p-7">
                            <div className="col-12 col-md-6 mb-5 fv-row">
                              <label
                                htmlFor="bookname"
                                className="required form-label mb-3"
                              >
                                Book Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="bookname"
                                name="Name"
                                value={rowdata.Name || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-6 mb-5 fv-row">
                              <label
                                htmlFor="author"
                                className="required form-label mb-3"
                              >
                                Author
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="author"
                                name="Author"
                                value={rowdata.Author || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="category"
                                className=" form-label mb-3"
                              >
                                Cateogry
                              </label>
                              <Select
                                // style={{ width: 180 }}
                                placeholder="Choose Category"
                                id="category"
                                value={rowdata.CategoryName}
                                onChange={(value) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    CategoryName: value,
                                    BookCatId: value,
                                  }));
                                }}
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="book_type"
                                className=" form-label mb-3"
                              >
                                Book type
                              </label>
                              <Select
                                required
                                // style={{ width: 180 }}
                                placeholder="Book Type"
                                id="book_type"
                                value={rowdata.BookTypeId}
                                onChange={(value) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    BookTypeId: value,
                                  }));
                                }}
                                options={typeOptions}
                              />
                            </div>

                            <div className="col-12 col-md-6 mb-5 d-flex flex-column">
                              <label
                                htmlFor="publisher"
                                className="required form-label mb-3"
                              >
                                Publisher
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="publisher"
                                name="Publisher"
                                value={rowdata.Publisher || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-6 mb-5 d-flex flex-column">
                              <label
                                htmlFor="purchase_date"
                                className="required form-label mb-3"
                              >
                                Purchased Date
                              </label>
                              <input
                                type="date"
                                style={{ width: 180 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="purchase_date"
                                name="PurchasedDate"
                                value={rowdata.PurchasedDate || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="edition"
                                className="required form-label mb-3"
                              >
                                Edition
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="edition"
                                name="Edition"
                                value={rowdata.Edition || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="price"
                                className="required form-label mb-3"
                              >
                                Price
                              </label>
                              <input
                                required
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="price"
                                name="Price"
                                value={rowdata.Price || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="quantity"
                                className="required form-label mb-3"
                              >
                                Quantity
                              </label>
                              <input
                                required
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="quantity"
                                name="Quantity"
                                value={rowdata.Quantity || ""}
                                readOnly={rowdata.IsLabled === true}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleEditBook}
                              >
                                Save
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger ms-2"
                                data-bs-dismiss="offcanvas"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>

                        {/* OFFCANVAS FOR ASSIGN TO INVENTORY  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvaseditinventory"
                          aria-labelledby="offcanvasExampleLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title"
                              id="offcanvaseditinventory"
                            >
                              Add Book Inventory
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form className="row p-7">
                            <div className="col-12 col-md-6 mb-5 fv-row">
                              <label
                                htmlFor="bookname"
                                className=" form-label mb-3"
                              >
                                Book Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="bookname"
                                name="Name"
                                value={rowdata.Name || ""}
                                readOnly
                              />
                            </div>

                            <div className="col-12 col-md-6 mb-5 fv-row">
                              <label
                                htmlFor="author"
                                className=" form-label mb-3"
                              >
                                Author
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="author"
                                name="Author"
                                value={rowdata.Author || ""}
                                readOnly
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="category"
                                className=" form-label mb-3"
                              >
                                Cateogry
                              </label>
                              <input
                                style={{ width: 180 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder="Course"
                                id="category"
                                value={rowdata.CategoryName || courseOptions}
                                readOnly
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="book_type"
                                className=" form-label mb-3"
                              >
                                Book type
                              </label>
                              <input
                                required
                                style={{ width: 180 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder="Book Type"
                                id="book_type"
                                value={rowdata.BookTypeId || ""}
                                readOnly
                              />
                            </div>

                            <div className="col-12 col-md-6 mb-5 d-flex flex-column">
                              <label
                                htmlFor="publisher"
                                className=" form-label mb-3"
                              >
                                Publisher
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="publisher"
                                name="Publisher"
                                value={rowdata.Publisher || ""}
                                readOnly
                              />
                            </div>

                            <div className="col-6 col-md-6 mb-5 d-flex flex-column">
                              <label
                                htmlFor="purchase_date"
                                className="required form-label mb-3"
                              >
                                Purchased Date
                              </label>
                              <input
                                type="date"
                                style={{ width: 180 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="purchase_date"
                                name="PurchasedDate"
                                value={rowdata.PurchasedDate || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="edition"
                                className="required form-label mb-3"
                              >
                                Edition
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="edition"
                                name="Edition"
                                value={rowdata.Edition || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="price"
                                className="required form-label mb-3"
                              >
                                Price
                              </label>
                              <input
                                required
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="price"
                                name="Price"
                                value={rowdata.Price || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>

                            <div className="col-6 col-md-4 mb-5 fv-row">
                              <label
                                htmlFor="quantity"
                                className="required form-label mb-3"
                              >
                                Quantity
                              </label>
                              <input
                                required
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="quantity"
                                name="Quantity"
                                value={rowdata.Quantity || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddBookInventory}
                              >
                                Save
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger ms-2"
                                data-bs-dismiss="offcanvas"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                        {/* OFFCANVAS FOR Bulk upload  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvasupload"
                          aria-labelledby="offcanvasUploadLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title "
                              id="offcanvasUploadLabel"
                            >
                              Upload
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          {excloading && (
                            <div className="d-flex justify-content-center">
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                          <form className="row p-7">
                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="course"
                                className="required form-label mb-3"
                              >
                                Category
                              </label>
                              <Select
                                // style={{ width: 180 }}
                                placeholder="Choose Category"
                                id="category"
                                value={
                                  excdetails.BookCatId === "NULL"
                                    ? null
                                    : excdetails.BookCatId
                                }
                                onChange={(value) => {
                                  setExcDetails((prev) => ({
                                    ...prev,
                                    BookCatId: value,
                                  }));
                                }}
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="branch"
                                className="required form-label mb-3"
                              >
                                Book Type
                              </label>
                              <Select
                                required
                                // style={{ width: 180 }}
                                placeholder="Book Type"
                                id="book_type"
                                value={
                                  excdetails.BookTypeId === "NULL"
                                    ? null
                                    : excdetails.BookTypeId
                                }
                                onChange={(value) => {
                                  setExcDetails((prev) => ({
                                    ...prev,
                                    BookTypeId: value,
                                  }));
                                }}
                                options={typeOptions}
                              />
                            </div>
                            <div className="mt-3">
                              <input id="fileinput" type="file" />
                            </div>

                            <div className=" form-label mt-3">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleExcelUpload}
                              >
                                {uploadText}
                              </button>
                              {/* <p>{message}</p> */}
                              <input
                                id="fileinput"
                                type="file"
                                ref={fileInputRef} // Assign the ref to the file input
                                className="d-none"
                                style={{ display: "none" }} // Hide the file input
                                // onChange={handleExcelUpload}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {getData.length > 0 ? (
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_customers_table"
                        >
                          <thead>
                            <tr className="text-start fw-bold fs-7 text-uppercase gs-0 tableheader">
                              <th className="text-center min-w-70px px-5">
                                Actions
                              </th>
                              <th className="min-w-125px">Name</th>
                              <th className="min-w-125px">Author</th>
                              <th className="min-w-125px">Publisher</th>
                              <th className="min-w-125px">Category</th>
                              <th className="min-w-100px">Type</th>
                              <th className="min-w-100px">Edition</th>
                              <th className="min-w-100px">Quantity</th>
                              <th className="min-w-100px">Price</th>
                              <th className="min-w-125px">Purchased on</th>
                              <th className="min-w-100px">IsLabeled</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {getData?.map((item) => (
                              <tr key={item.BookInventoryId}>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    className="btn btn-sm btn-primary btn-flex btn-center btn-active-light-primary"
                                    style={{
                                      backgroundColor: "#8096e0",
                                    }}
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </button>
                                  <div className="dropdown mt-3">
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvaseditbook"
                                          aria-controls="offcanvasExample"
                                          onClick={() => handleEdit(item)}
                                        >
                                          Edit
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvaseditinventory"
                                          aria-controls="offcanvasExample"
                                          onClick={() =>
                                            handleAddInventory(item)
                                          }
                                        >
                                          Add to Inventory
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={() => handleDeleteBook(item)}
                                        >
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <a className="text-gray-800 text-hover-primary mb-1">
                                    {item.Name}
                                  </a>
                                </td>
                                <td>{item.Author}</td>
                                <td>
                                  {/* <div className="badge badge-light-danger">
                                Locked
                              </div> */}
                                  <a
                                    className="text-gray-600 text-hover-primary mb-1"
                                    style={{
                                      maxWidth: "125px",
                                      display: "inline-block",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.Publisher > 15
                                      ? item.Publisher.slice(0, 15) + "..."
                                      : item.Publisher}
                                  </a>
                                </td>
                                <td>{item.CategoryName}</td>
                                <td>{item.BookType}</td>
                                <td>{item.Edition}</td>
                                <td>{item.Quantity}</td>
                                <td>{item.Price}</td>
                                <td>{formatDate(item.PurchasedDate)}</td>
                                <td className="text-center">
                                  <div className="border border-danger rounded w-40px text-danger text-center">
                                    {item.IsLabled === true ? "Yes" : "No"}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center">
                      {errMsg ? errMsg : "No Records Found"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookTitles;
