import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { API_BASE_LB_URL } from "../Config";
import { useParams } from "react-router-dom";
import { get } from "react-hook-form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLabelContext } from "../context";

const GenerateLabels = () => {
  const [labelData, setLabelData] = useState([]);
  const [editLabelId, setEditLabelId] = useState(null);
  const [newLabelName, setNewLabelName] = useState("");
  const [getdata, setGetData] = useState();
  const [filtered, setFiltered] = useState(false);
  const [logindata, setlogindata] = useState();
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [orgId, setOrgId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const [labels, setLabels] = useState([]);
  const { labelDataa } = useLabelContext();
  const navigate = useNavigate();

  const { returnid } = useParams();

  const [details, setDetails] = useState([]);

  const handlecancel = (e) => {
    e.preventDefault();
  };

  const handleSave = async () => {
    const requestData = {
      Id: details.labelId,
      BookId: details.BookId,
      InventoryId: details.InventoryId,
      Name: details.LabelName,
      Status: 2,
      UpdatedBy: updatedId,
      IsActive: 1,
      BookRackId: 2,
    };
    console.log(requestData);
    try {
      const response = await fetch(
        `
        ${API_BASE_LB_URL}booklabel/booklabelEdit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/booklabels");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          setOrgId(orgId);
          setUpdatedId(UpdatedById);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const storedData = sessionStorage.getItem("labelData");
    if (storedData) {
      setLabelData(JSON.parse(storedData));
    }
    const storedlogindata = sessionStorage.getItem("userDetails");
    if (storedlogindata) {
      setlogindata(JSON.parse(storedlogindata));
    }
    // const getGenerateLabelFromDB = async () => {
    //   try {
    //     const response = await fetch(`
    //       ${API_BASE_LB_URL}booklabel/getLabelsByInventoryId?InventoryId=${returnid}`);

    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }

    //     const result = await response.json();
    //     console.log("result:", result);

    //     if (result[0].IsLabled === false) {
    //       setFiltered(true);
    //       console.log(filtered);
    //     }

    //     // sessionStorage.setItem('labelData', JSON.stringify(result));
    //     if (result.length === 1) {
    //       const newItems = Array.from(
    //         { length: result[0].Quantity },
    //         (_, index) => ({
    //           ...result[0],
    //         })
    //       );
    //       console.log("data passed", newItems);
    //       setGetData(newItems);
    //     } else {
    //       setGetData(result);
    //     }
    //   } catch (error) {
    //     console.log("Fetch error:", error);
    //   } finally {
    //   }
    // };
    if (labelDataa) {
      sessionStorage.setItem("labelDataa", JSON.stringify(labelDataa));
    }

    const storedLabelDataa = sessionStorage.getItem("labelDataa");

    // If data exists, parse it and set it in state
    if (storedLabelDataa) {
      setGetData(JSON.parse(storedLabelDataa));
    }
    if (labelDataa) {
      const shouldShowButton = labelDataa.some((book) => !book.IsLabled);
      setFiltered(shouldShowButton);
    }
  }, [labelDataa, returnid, filtered, updatedId]);

  const handleLabelChange = (labelId, newLabelName) => {
    console.log(`Updating labelId ${labelId} with new name: ${newLabelName}`);
    const updatedLabelData = getdata.map((item) =>
      item.labelId === labelId ? { ...item, LabelName: newLabelName } : item
    );
    setGetData(updatedLabelData);
    setNewLabelName(updatedLabelData.labelName);
  };

  const handleDeleteClick = async (labelId) => {
    const itemToDelete = getdata.find((item) => item.labelId === labelId);
    console.log("label id:", itemToDelete.labelId);
    if (!itemToDelete) {
      console.error("Item not found");
      return;
    }

    const requestData = {
      UpdatedBy: updatedId,
      LabelId: labelId,
    };
    console.log(requestData, logindata);

    try {
      const response = await fetch(
        `
        ${API_BASE_LB_URL}booklabel/DELTbookLabels`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      console.log("Response:", response);

      if (response.ok) {
        // Double-check if deletion was successful
        const updatedLabelData = getdata.filter(
          (item) => item.labelId !== labelId
        );

        if (updatedLabelData.length !== getdata.length) {
          setLabelData(updatedLabelData);
          sessionStorage.setItem("labelData", JSON.stringify(updatedLabelData));
          console.log("Data deleted successfully");
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "success.",
            confirmButtonText: "OK",
          }).then((res) => {
            if (res.isConfirmed) {
              navigate("/booklabels");
            }
          });
        } else {
          console.warn(
            "No item was removed from the state. Possible deletion issue."
          );
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGenerateLabels = async () => {
    const newLabels = [];
    for (let i = 0; i <= getdata.length - 1; i++) {
      newLabels.push(`${prefix}${Number(suffix) + i}`);
    }

    // Update getdata with new labels
    // for (let i = 0; i < getdata.length; i++) {
    //   getdata[i].LabelName = newLabels[i];
    // }

    // console.log(getdata)

    const requestData = getdata.map(({ BookId, InventoryId }, index) => ({
      BookId,
      InventoryId,
      Name: newLabels[index],
      Status: 1,
      CreatedBy: updatedId,
    }));
    console.log(requestData);

    try {
      const response = await fetch(
        `
        ${API_BASE_LB_URL}booklabel/GenerateBookLabels`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error(`
          Failed to generate labels. Status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/booklabels");
          }
        });
      }

      const contentType = response.headers.get("Content-Type");

      let responseData;

      if (contentType && contentType.includes("application/json")) {
        responseData = await response.json();

        if (responseData.status === "success") {
          console.log("Labels generated successfully:", responseData.message);
          // Additional actions if needed
        } else {
          console.error("Server response error:", responseData.message);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // const handleLabChange = (e) => {
  //   setNewLabelName(e.target.value);
  // };

  const handleUpdateClick = async (item) => {
    console.log(item);
    setDetails(item);
    // const requestData = {
    //   Id: item.labelId,
    //   BookId: item.BookId,
    //   InventoryId: item.InventoryId,
    //   Name: item.LabelName,
    //   Status: 2,
    //   UpdatedBy: updatedId,
    //   IsActive: 1,
    //   BookRackId: 2,
    // };
    // console.log(requestData);
    // try {
    //   const response = await fetch(
    //     `
    //     ${API_BASE_LB_URL}booklabel/booklabelEdit`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify(requestData),
    //     }
    //   );

    //   if (response.ok) {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Success!",
    //       text: "success.",
    //       confirmButtonText: "OK",
    //     });
    //   }
    // } catch (error) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops!",
    //     text: "Something went wrong. Please try again.",
    //     confirmButtonText: "Retry",
    //   });
    // }
  };

  // const handleUpdateClick = async (labelId) => {
  //   const itemToUpdate = getdata.find(item => item.labelId === labelId);
  //   if (!itemToUpdate) {
  //     console.error('Item not found');
  //     return;
  //   }
  //   const requestData = {
  //     Id: itemToUpdate.labelId,
  //     BookId: itemToUpdate.BookId,
  //     InventoryId: itemToUpdate.InventoryId,
  //     Name: newLabelName,
  //     Status: 2,
  //     UpdatedBy: 1002,
  //     IsActive: 1,
  //     BookRackId: 2
  //   };

  //   try {
  //     const response = await fetch(${API_BASE_LB_URL}booklabel/booklabelEdit, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     if (response.ok) {
  //       console.log('Data updated successfully');
  //       // Update local state with new label name
  //       handleLabelChange(labelId, newLabelName);
  //     } else {
  //       console.error('Failed to update data');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  // const handleUpdateButtonClick = (labelId) => {
  //   const itemToUpdate = labelData.find(item => item.labelId === labelId);
  //   if (itemToUpdate) {
  //     setEditLabelId(labelId);
  //     setNewLabelName(itemToUpdate.LabelName);
  //   }
  // };

  return (
    <>
      <Header />
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Generate Labels
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    {filtered && (
                      <div className="card-title row">
                        <div className="col-8 col-md-4 d-flex align-items-center position-relative my-1">
                          <input
                            type="text"
                            className="form-control form-control-solid w-200px w-md-150px ps-13"
                            placeholder="Prefix"
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}
                          />
                        </div>
                        <div className="col-8 col-md-4 d-flex align-items-center position-relative my-1 ms-2">
                          <input
                            type="text"
                            className="form-control form-control-solid w-200px w-md-150px ps-13"
                            placeholder="Suffix"
                            value={suffix}
                            onChange={(e) => setSuffix(e.target.value)}
                          />
                        </div>
                        <div className="col-8 col-md-3 mt-1 mx-2 w-100px my-1">
                          <button
                            type="button"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                            onClick={handleGenerateLabels}
                          >
                            Generate
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                            <th className="min-w-125px px-5">Name</th>
                            <th className="min-w-125px">Author</th>
                            <th className="min-w-125px">Label</th>
                            <th className="min-w-120px px-3 text-start">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {getdata ? (
                            getdata
                              .filter((item) => item.IsActive)
                              .map((item, index) => (
                                <tr key={index}>
                                  <td className="px-5">
                                    <a className="text-gray-800 text-hover-primary  mb-1 ">
                                      {item.Name}
                                    </a>
                                  </td>
                                  <td>{item.Author}</td>
                                  <td>{item.LabelName}</td>
                                  {/* <td>
                                    <input
                                      type="text"
                                      value={item.LabelName || newLabelName}
                                      className="form-control w-150px"
                                      onChange={(e) =>
                                        handleLabelChange(
                                          item.labelId,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td> */}
                                  <td className="text-start">
                                    <button
                                      type="button"
                                      className="bg-transparent border-0"
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasExample"
                                      aria-controls="offcanvasExample"
                                      onClick={() => handleUpdateClick(item)}
                                    >
                                      <i
                                        className="fa-regular fa-pen-to-square text-success mx-1"
                                        style={{ fontSize: 20 }}
                                      ></i>
                                    </button>
                                    <button
                                      className="bg-transparent  border-0"
                                      onClick={() =>
                                        handleDeleteClick(item.labelId)
                                      }
                                    >
                                      <i
                                        className="fa-solid fa-trash text-danger"
                                        style={{ fontSize: 20 }}
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        style={{ width: "500px" }}
      >
        <div className="offcanvas-header bg-secondary">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Edit Label
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <form className="row p-7">
          <div
            className="col-12 col-md-6 mb-5 d-flex flex-column"
            style={{ height: "70px" }}
          >
            <label htmlFor="bookname" className="required form-label mb-3">
              Book Name
            </label>
            <input
              required
              type="text"
              className="form-control form-control-sm form-control-solid"
              // name="campaign_name"
              placeholder=""
              id="bookname"
              name="Name"
              value={details.Name || ""}
              readOnly
              // onChange={(e) => {
              //   setDetails((prev) => ({
              //     ...prev,
              //     [e.target.name]: e.target.value,
              //   }));
              // }}
            />
          </div>

          <div className="col-12 col-md-6 mb-5 fv-row">
            <label htmlFor="author" className=" form-label mb-3">
              Author
            </label>
            <input
              type="text"
              className="form-control form-control-sm form-control-solid"
              // name="campaign_name"
              placeholder=""
              id="author"
              name="Author"
              value={details.Author || ""}
              readOnly
            />
          </div>

          <div className="col-12 col-md-6 mb-5 fv-row">
            <label htmlFor="labelname" className=" form-label mb-3">
              Label Name
            </label>
            <input
              type="text"
              className="form-control form-control-sm form-control-solid"
              // name="campaign_name"
              placeholder=""
              id="labelname"
              name="LabelName"
              value={details.LabelName || ""}
              onChange={(e) => {
                setDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </div>

          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-danger ms-2"
              data-bs-dismiss="offcanvas"
              onClick={handlecancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default GenerateLabels;
