import React, { useState, useRef, useEffect } from "react";
import { Select } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import * as XLSX from "xlsx";
import { API_BASE_LB_URL } from "../Config";
import { saveAs } from "file-saver";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Employees = (props) => {
  const [fileName, setFileName] = useState("");
  const [rowdata, setRowdata] = useState({});
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [excelData, setExcelData] = useState([]);
  const [getData, setGetData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const fileInputRef = useRef(null);
  const [course, setCourse] = useState([]);
  const [branch, setBranch] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const [errMsg, setErrMsg] = useState("Loading...");
  const [progid, setProgId] = useState(2);
  const [excloading, setExcLoading] = useState(false);
  const [uploadText, setUploadText] = useState("Upload");
  const [excdetails, setExcDetails] = useState({
    ProgramName: "NULL",
    CourseName: "NULL",
  });

  const [empdetails, setEmpDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_no: "",
    qualification: "",
    emp_id: "",
    gender: "NULL",
    course: "NULL",
    branch: "NULL",
    address: "",
    date: "",
    emp_id: "",
  });
  const excdata = [
    [
      "First Name",
      "Last Name",
      "Qualification",
      "Mobile Number",
      "Gender",
      "D.O.B",
      "Address",
    ],

    // Add more rows as needed
  ];
  const handleEdit = (item) => {
    setRowdata(item);
    console.log(item);
  };
  const courseOptions = course.map((course) => ({
    value: course.Id,
    label: course.Name,
  }));

  const branchOptions = branch.map((course) => ({
    value: course.Id,
    label: course.CourseName,
  }));

  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          // console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
          setUpdatedId(UpdatedById);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  const handleDelete = async (item) => {
    // console.log(item);
    const requestData = {
      Id: item.regid,
    };
    try {
      // Make a POST request to update data
      const response = await fetch(`${API_BASE_LB_URL}library/DELTLibraryReg`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      // console.log(response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}library/getRegistrations?OrgId=${orgId}&isStaff=1&SearchText='${searchInput}'`
      );
      if (!response.ok) {
        setGetData([]);
      }
      const result = await response.json();
      const limitedResult = result.slice(0, 20);
      setGetData(limitedResult);
    } catch (error) {
      setErrMsg("No Records Found");
    } finally {
      setLoading(false);
    }
  };

  const generateExcel = () => {
    // Create a new workbook
    const ws = XLSX.utils.aoa_to_sheet(excdata);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate buffer and save the file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "employees.xlsx");
  };

  const handleExcelUpload = (e) => {
    e.preventDefault();
    console.log(excdetails);
    const file = document.getElementById("fileinput").files[0];
    if (excdetails.CourseName !== "NULL" && excdetails.ProgramName !== "NULL") {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });

          const formatDateToDDMMYYYY = (dateString) => {
            const [year, month, day] = dateString.split("-").map(Number);
            return `${year}-${month.toString().padStart(2, "0")}-${day
              .toString()
              .padStart(2, "0")}`;
          };

          const formattedData = jsonData.map((item) => {
            const serialDate = item["D.O.B"] === undefined ? "" : item["D.O.B"];
            console.log(serialDate);
            if (isNaN(serialDate)) {
              // console.error(`Invalid serial date: ${serialDate}`);
              return {
                first_name: item["First Name"] ? item["First Name"] : "",
                last_name: item["Last Name"] ? item["Last Name"] : "",
                address: item.Address ? item.Address : "",
                branch: "" || excdetails.CourseName,
                course: "" || excdetails.ProgramName,
                dob: "" || serialDate, // Handle invalid dates gracefully
                gender:
                  item.Gender?.toLowerCase() === "male"
                    ? 0
                    : item.Gender?.toLowerCase() === "female"
                    ? 1
                    : "NULL",
                qualification: item.Qualification ? item.Qualification : "",
                mobile_no: item["Mobile Number"]
                  ? String(item["Mobile Number"])
                  : "",
              };
            }
            console.log(serialDate);
            const date = new Date(
              baseDate.getTime() + serialDate * 24 * 60 * 60 * 1000
            );
            console.log(date);
            const isoDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            const formattedDate = formatDateToDDMMYYYY(isoDate); // Convert to DD/MM/YYYY
            return {
              first_name: item["First Name"] ? item["First Name"] : "",
              last_name: item["Last Name"] ? item["Last Name"] : "",
              address: item.Address ? item.Address : "",
              branch: "" || excdetails.CourseName,
              course: "" || excdetails.ProgramName,
              dob: "" || formattedDate,
              gender:
                item.Gender?.toLowerCase() === "male"
                  ? 0
                  : item.Gender?.toLowerCase() === "female"
                  ? 1
                  : "NULL",
              qualification: item.Qualification ? item.Qualification : "",
              mobile_no: item["Mobile Number"]
                ? String(item["Mobile Number"])
                : "",
            };
          });

          // STARTING CODE FOR CHUNK EXCEL
          const chunkSize = 50;
          if (formattedData.length > chunkSize) {
            alert(`Please enter not more than ${chunkSize} records.`);
            return;
          }
          // let index = 0;
          // const totalChunks = Math.ceil(formattedData.length / chunkSize);
          const totalChunks = 1;
          let accumulatedData = [];

          const processChunk = async (chunkIndex) => {
            if (chunkIndex >= totalChunks) {
              // setStatus("Completed");
              setExcelData(accumulatedData);
              return;
            }

            const startIndex = chunkIndex * chunkSize;
            const endIndex = Math.min(
              startIndex + chunkSize,
              formattedData.length
            );
            const chunk = formattedData.slice(startIndex, endIndex);

            // Simulate updating the chunk
            console.log(
              `Processing chunk ${chunkIndex + 1}/${totalChunks}`,
              chunk
            );

            // Accumulate processed data
            accumulatedData = accumulatedData.concat(chunk);

            // // Update the index
            // index += 1;

            // Delay to simulate async processing

            await new Promise((resolve) => setTimeout(resolve, 1000));
            console.log(excelData);

            // Process next chunk
            processChunk(chunkIndex + 1);
          };

          processChunk(0);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  // const formatDate = (dateString) => {
  //   if (!dateString) return "";
  //   const date = new Date(dateString);

  //   // Ensure the date is valid
  //   if (isNaN(date.getTime())) return "";

  //   // Format to YYYY-MM-DD
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${day}-${month}-${year}`;
  // };

  const baseDate = new Date(1900, 0, 0); // January 1, 1900

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      // Read the file using FileReader
      setFileName(uploadedFile.name);
      // setStatus("Uploading");
      const reader = new FileReader();
      reader.onload = (e) => {
        // Parse the file data with xlsx
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the data is in the first sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });

        const formatDateToDDMMYYYY = (dateString) => {
          const [year, month, day] = dateString.split("-").map(Number);
          return `${month.toString().padStart(2, "0")}/${day
            .toString()
            .padStart(2, "0")}/${year}`;
        };

        const formattedData = jsonData.map((item) => {
          const serialDate = item["D.O.B"];
          if (isNaN(serialDate)) {
            // console.error(`Invalid serial date: ${serialDate}`);
            return {
              first_name: item["First Name"],
              last_name: item["Last Name"],
              address: item.Address,
              qualification: item.Qualification,
              dob: serialDate, // Handle invalid dates gracefully
              gender: item.Gender,
              mobile_no: String(item["Mobile Number"]),
            };
          }
          const date = new Date(
            baseDate.getTime() + serialDate * 24 * 60 * 60 * 1000
          );
          const isoDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
          const formattedDate = formatDateToDDMMYYYY(isoDate); // Convert to DD/MM/YYYY
          return {
            first_name: item["First Name"],
            last_name: item["Last Name"],
            address: item.Address,
            qualification: item.Qualification,
            dob: formattedDate,
            gender: item.Gender,
            mobile_no: String(item["Mobile Number"]),
          };
        });

        // STARTING CODE FOR CHUNK EXCEL
        const chunkSize = 50;
        if (formattedData.length > chunkSize) {
          alert(`Please enter not more than ${chunkSize} records.`);
          return;
        }
        // let index = 0;
        // const totalChunks = Math.ceil(formattedData.length / chunkSize);
        const totalChunks = 1;
        let accumulatedData = [];

        const processChunk = async (chunkIndex) => {
          if (chunkIndex >= totalChunks) {
            // setStatus("Completed");
            setExcelData(accumulatedData);
            return;
          }

          const startIndex = chunkIndex * chunkSize;
          const endIndex = Math.min(
            startIndex + chunkSize,
            formattedData.length
          );
          const chunk = formattedData.slice(startIndex, endIndex);

          // Simulate updating the chunk
          console.log(
            `Processing chunk ${chunkIndex + 1}/${totalChunks}`,
            chunk
          );

          // Accumulate processed data
          accumulatedData = accumulatedData.concat(chunk);

          // // Update the index
          // index += 1;

          // Delay to simulate async processing

          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Process next chunk
          processChunk(chunkIndex + 1);
        };

        // END CODE FOR CHUNK EXCEL

        processChunk(0);
      };
      reader.readAsArrayBuffer(uploadedFile);
      // console.log("Final setExcelData : ", excelData);
    }
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    // console.log(empdetails);
    if (empdetails.first_name === "") {
      alert("Enter Name");
    } else if (empdetails.course === "NULL") {
      alert("Select Course");
    } else if (empdetails.branch === "NULL") {
      alert("Select Branch");
    } else if (empdetails.gender === "NULL") {
      alert("Select Gender");
    } else {
      const requestData = {
        OrgId: orgId,
        HallTicketNo: empdetails.emp_id,
        FirstName: empdetails.first_name,
        LastName: empdetails.last_name,
        FullName: empdetails.first_name + " " + empdetails.last_name,
        Gender: empdetails.gender,
        DOB: empdetails.date,
        Mobile: empdetails.mobile_no,
        Address: empdetails.address,
        CreatedBy: updatedId,
        // UpdatedBy: 1,
        IsActive: true,
        EmpQualification: empdetails.qualification,
        CourseId: empdetails.branch,
        ProgramId: empdetails.course,
        IsStaff: 1,
        FingerPrint: "NULL",
      };
      console.log("Request Body:", JSON.stringify(requestData, null, 2));

      try {
        // Make a POST request to update data
        const response = await fetch(
          `${API_BASE_LB_URL}library/AddLibraryReg`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        console.log(await response.json());

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "success.",
            confirmButtonText: "OK",
          }).then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        }

        // Clear the form after successful update
        setEmpDetails({
          first_name: "",
          last_name: "",
          mobile_no: "",
          qualification: "",
          gender: "",
          address: "",
          date: "",
          emp_id: "",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setEmpDetails({
      first_name: "",
      last_name: "",
      mobile_no: "",
      qualification: "",
      gender: "NULL",
      address: "",
      date: "",
      emp_id: "",
    });
  };

  const handleEditEmployee = async (e) => {
    e.preventDefault();
    console.log(rowdata);
    // console.log("ID :", rowdata.regid);
    const requestData = {
      OrgId: orgId,
      HallTicketNo: rowdata.EmpId ? rowdata.EmpId : "",
      ID: rowdata.regid,
      FullName: rowdata.FirstName + " " + rowdata.LastName,
      FirstName: rowdata.FirstName ? rowdata.FirstName : "",
      LastName: rowdata.LastName ? rowdata.LastName : "",
      DOB: rowdata.Dob ? rowdata.Dob : "",
      Gender: "" || rowdata.gender,
      Mobile: rowdata.Mobile ? rowdata.Mobile : "",
      CourseId: rowdata.CourseId || rowdata.CourseName,
      ProgramId: rowdata.ProgramId || rowdata.ProgramName,
      Address: rowdata.Address ? rowdata.Address : "",
      EmpQualification: rowdata.EmpQualification
        ? rowdata.EmpQualification
        : "",
      IsStaff: 1,
      UpdatedBy: updatedId,
      FingerPrint: "NULL",
    };

    console.log("Request Body:", JSON.stringify(requestData, null, 2));
    try {
      // Make a POST request to update data
      const response = await fetch(`${API_BASE_LB_URL}library/EditLibraryReg`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const responseBody = await response.json();
      console.log(responseBody);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.reload();
          }
        });
      }

      // console.log("Update successful");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };
  useEffect(() => {
    if (orgId) {
      //CODE FOR EXCEL UPLOADING
      const fetchData = async () => {
        if (excelData.length === 0) return;
        let successfulRecords = 0;
        let errorOccurred = false;
        setExcLoading(true);
        setUploadText("Uploading...");

        try {
          for (let i = 0; i < excelData.length; i++) {
            const item = excelData[i];

            try {
              const response = await fetch(
                `${API_BASE_LB_URL}library/AddLibraryReg`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    OrgId: orgId,
                    ProgramId: item.course,
                    CourseId: item.branch,
                    HallTicketNo: "",
                    FirstName: item.first_name,
                    LastName: item.last_name,
                    FullName: item.first_name + " " + item.last_name,
                    Gender: item.gender,
                    DOB: String(item.dob),
                    Mobile: item.mobile_no,
                    Address: item.address,
                    CreatedBy: updatedId,
                    IsActive: true,
                    EmpQualification: item.qualification,
                    IsStaff: 1,
                    FingerPrint: "NULL",
                  }),
                }
              );

              const responseText = await response.json();
              console.log(response);
              console.log("Response Text:", responseText);

              if (responseText.ResultData[0].Status === "Failure") {
                errorOccurred = true; // Mark as error if any record fails

                Swal.fire({
                  icon: "error",
                  title: "Oops!",
                  text: `Something went wrong with the request. ${successfulRecords} record(s) uploaded. Check the record from row ${
                    i + 2
                  }.`,
                  confirmButtonText: "Retry",
                });
                break;
              } else {
                successfulRecords += 1;
              }
            } catch (innerEror) {
              errorOccurred = true;
              Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Something went wrong with the request. Please try again.",
                confirmButtonText: "Retry",
              });
              break; // Exit the loop to stop processing
            }
          }
          if (!errorOccurred && successfulRecords > 0) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "All records processed successfully.",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          // Handle general errors
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "Something went wrong. Please try again.",
            confirmButtonText: "Retry",
          });
        } finally {
          setExcelData([]);
          setExcLoading(false);
          setUploadText("Upload");
          setExcDetails({
            ProgramName: "NULL",
            CourseName: "NULL",
          });

          const fileInput = document.getElementById("fileinput");
          if (fileInput) {
            fileInput.value = ""; // Clear the input field
          }
        }
      };
      if (excelData.length > 0) {
        fetchData();
      }

      const getDataFromDB = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}library/getRegistrations?OrgId=${orgId}&isStaff=1&SearchText=NULL`
          );
          if (!response.ok) {
            setErrMsg("No Records Found");
          }
          const result = await response.json();
          const limitedResult = result.slice(0, 20);
          setGetData(limitedResult);
        } catch (error) {
          setErrMsg("Try Again");
        } finally {
          setLoading(false);
        }
      };

      const getCourse = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}library/getprogramddl?OrgId=${orgId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();

          setCourse(result);
          // setExcelData((prev) => ({ ...prev, CourseName: "NULL"}));
        } catch (error) {
          setErrMsg("Network Error");
        }
      };

      const getBranch = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}library/getcourseddl?OrgId=${orgId}&ProgramId=${progid}`
          );
          if (!response.ok) {
            setErrMsg("Network Error");
          }
          const result = await response.json();

          setBranch(result);
        } catch (error) {
          setErrMsg("Network Error");
        }
      };

      getDataFromDB();
      getCourse();
      getBranch();
    }
  }, [excelData, progid, orgId, updatedId]);

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-2" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Employees
                  </h1>
                </div>
                <button
                  type="button"
                  className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  aria-controls="offcanvasExample"
                >
                  Add Employee
                  {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                </button>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          data-kt-customer-table-filter="search"
                          className="form-control form-control-solid w-200px w-lg-250px ps-13"
                          placeholder="Search with Name or Mobile"
                          value={"" || searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-2"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      >
                        {/* <div className="me-3">
                          <select
                            className="form-select form-select-solid w-100px"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Status"
                            data-kt-ecommerce-order-filter="status"
                          >
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="locked">Locked</option>
                          </select>
                        </div> */}
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasupload"
                          aria-controls="offcanvasUpload"
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Import
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef} // Assign the ref to the file input
                          className="d-none"
                          style={{ display: "none" }} // Hide the file input
                          onChange={handleFileChange}
                        />
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          onClick={generateExcel}
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Excel Template
                        </button>
                        {/* {fileName && <p>Selected file: {fileName}</p>} */}
                        {/* <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_add_customer"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasExample"
                          aria-controls="offcanvasExample"
                        >
                          Add Employee
                        </button> */}
                        <style>
                          {`
          .offcanvas.custom-offcanvas {
            width: 400px !important; /* Adjust this as needed */
          }

          @media (max-width: 576px) {
            .offcanvas.custom-offcanvas {
              width: 70% !important; /* Adjust the percentage as needed */
            }
          }
        `}
                        </style>
                        {/* OFFCANVAS FOR ADD EMPLOYEE  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvasExample"
                          aria-labelledby="offcanvasExampleLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title"
                              id="offcanvasExampleLabel"
                            >
                              Add Employee
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form className="row p-7">
                            <div
                              className="col-12 col-md-6 mb-5 d-flex flex-column"
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor="firstname"
                                className="required form-label mb-3"
                              >
                                First Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="firstname"
                                name="first_name"
                                value={empdetails.first_name}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div
                              className="col-12 col-md-6 mb-5 d-flex flex-column"
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor="lastname"
                                className=" form-label mb-3"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="lastname"
                                name="last_name"
                                value={empdetails.last_name}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-4 mb-5 d-flex flex-column">
                              <label
                                htmlFor="qualification"
                                className=" form-label mb-3"
                              >
                                Qualification
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="qualification"
                                name="qualification"
                                value={empdetails.qualification}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-4 mb-5 d-flex flex-column">
                              <label
                                htmlFor="emp_id"
                                className=" form-label mb-3"
                              >
                                Employee Id
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="emp_id"
                                name="emp_id"
                                value={empdetails.emp_id}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-4 mb-5 d-flex flex-column">
                              <label
                                htmlFor="mobile"
                                className="required form-label mb-3"
                              >
                                Mobile
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="mobile"
                                name="mobile_no"
                                value={empdetails.mobile_no}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="course"
                                className="required form-label mb-3 "
                              >
                                Course
                              </label>
                              <Select
                                style={{ width: 160 }}
                                placeholder="Choose Course"
                                id="course"
                                optionFilterProp="label"
                                value={
                                  empdetails.course === "NULL"
                                    ? null
                                    : empdetails.course
                                }
                                onChange={(value) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    // ProgramId: value,
                                    course: value,
                                  }));
                                  setProgId(value);
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    branch: "NULL",
                                  }));
                                }}
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="branch"
                                className="required form-label mb-3"
                              >
                                Branch
                              </label>
                              <Select
                                style={{ width: 160 }}
                                placeholder="Choose Branch"
                                id="branch"
                                optionFilterProp="label"
                                // value={"" || details.CourseName}
                                value={
                                  empdetails.branch === "NULL"
                                    ? null
                                    : empdetails.branch
                                }
                                onChange={(value) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,

                                    branch: value,
                                  }));
                                }}
                                options={branchOptions}
                              />
                            </div>

                            <div
                              className="col-6 d-flex flex-column dropdown mt-3 "
                              required
                            >
                              <label
                                htmlFor="gender"
                                className="required form-label mb-3"
                              >
                                Gender
                              </label>
                              <Select
                                required
                                style={{ width: 160 }}
                                placeholder="Choose Gender"
                                id="gender"
                                optionFilterProp="label"
                                value={
                                  empdetails.gender === "NULL"
                                    ? null
                                    : empdetails.gender
                                }
                                onChange={(value) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    gender: value,
                                  }));
                                }}
                                options={[
                                  {
                                    value: 0,
                                    label: "Male",
                                  },
                                  {
                                    value: 1,
                                    label: "Female",
                                  },
                                ]}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3">
                              <label
                                htmlFor="date"
                                className=" form-label mb-3"
                              >
                                D.O.B
                              </label>
                              <input
                                type="date"
                                style={{ width: 160 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="date"
                                name="date"
                                value={empdetails.date}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 mb-5 fv-row">
                              <label
                                htmlFor="address"
                                className=" form-label mb-3"
                              >
                                Address
                              </label>
                              <textarea
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="address"
                                name="address"
                                value={empdetails.address}
                                onChange={(e) => {
                                  setEmpDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddEmployee}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger ms-2"
                                data-bs-dismiss="offcanvas"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                        {/* OFFCANVAS FOR EDIT EMPLOYEE  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvasedit"
                          aria-labelledby="offcanvasExampleLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title"
                              id="offcanvasExampleLabel"
                            >
                              Edit Employee Details
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form className="row p-7">
                            <div
                              className="col-12 col-md-6 mb-5 d-flex flex-column"
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor="firstname"
                                className="required form-label mb-3"
                              >
                                First Name
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="firstname"
                                name="FirstName"
                                value={rowdata.FirstName || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div
                              className="col-12 col-md-6 mb-5 d-flex flex-column"
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor="lastname"
                                className=" form-label mb-3"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="lastname"
                                name="LastName"
                                value={rowdata.LastName ? rowdata.LastName : ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-4 mb-5 d-flex flex-column">
                              <label
                                htmlFor="qualification"
                                className=" form-label mb-3"
                              >
                                Qualification
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="qualification"
                                name="EmpQualification"
                                value={rowdata.EmpQualification || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-4 mb-5 d-flex flex-column">
                              <label
                                htmlFor="emp_id"
                                className=" form-label mb-3"
                              >
                                Employee Id
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                // name="campaign_name"
                                placeholder=""
                                id="emp_id"
                                name="EmpId"
                                value={rowdata.EmpId || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-4 mb-5 d-flex flex-column">
                              <label
                                htmlFor="mobile"
                                className="required form-label mb-3"
                              >
                                Mobile
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="mobile"
                                name="Mobile"
                                value={rowdata.Mobile || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="col-6 d-flex flex-column dropdown  mt-3">
                              <label
                                htmlFor="course"
                                className="required form-label mb-3"
                              >
                                Course
                              </label>
                              <Select
                                style={{ width: 160 }}
                                placeholder="Choose Course"
                                id="course"
                                optionFilterProp="label"
                                value={rowdata.ProgramName || ""}
                                onChange={(value) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    ProgramId: value,
                                    ProgramName: value,
                                  }));

                                  setProgId(value);
                                }}
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="branch"
                                className="required form-label mb-3"
                              >
                                Branch
                              </label>
                              <Select
                                style={{ width: 160 }}
                                placeholder="Choose Branch"
                                id="branch"
                                optionFilterProp="label"
                                // value={"" || details.CourseName}
                                value={"" || rowdata.CourseName}
                                onChange={(value) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    CourseId: value,
                                    CourseName: value,
                                  }));
                                }}
                                options={branchOptions}
                              />
                            </div>

                            <div
                              className="col-6 d-flex flex-column dropdown mt-3 "
                              required
                            >
                              <label
                                htmlFor="gender"
                                className="required form-label mb-3"
                              >
                                Gender
                              </label>
                              <Select
                                required
                                style={{ width: 160 }}
                                placeholder="Choose Gender"
                                id="gender"
                                optionFilterProp="label"
                                value={rowdata.gender === 0 ? "Male" : "Female"}
                                onChange={(value) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    gender: value,
                                  }));
                                }}
                                options={[
                                  {
                                    value: 0,
                                    label: "Male",
                                  },
                                  {
                                    value: 1,
                                    label: "Female",
                                  },
                                ]}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3">
                              <label
                                htmlFor="date"
                                className=" form-label mb-3"
                              >
                                D.O.B
                              </label>
                              <input
                                type="date"
                                style={{ width: 160 }}
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="date"
                                name="Dob"
                                value={rowdata.Dob || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div className="col-12 mb-5 fv-row">
                              <label htmlFor="address">Address</label>
                              <textarea
                                className="form-control form-control-sm form-control-solid"
                                placeholder=""
                                id="address"
                                name="Address"
                                value={rowdata.Address || ""}
                                onChange={(e) => {
                                  setRowdata((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                              />
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleEditEmployee}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger ms-2"
                                data-bs-dismiss="offcanvas"
                              >
                                Cancal
                              </button>
                            </div>
                          </form>
                        </div>
                        {/* OFFCANVAS FOR Bulk upload  */}
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="offcanvasupload"
                          aria-labelledby="offcanvasUploadLabel"
                          style={{ width: "500px" }}
                        >
                          <div className="offcanvas-header bg-secondary">
                            <h5
                              className="offcanvas-title "
                              id="offcanvasUploadLabel"
                            >
                              Upload
                            </h5>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          {excloading && (
                            <div className="d-flex justify-content-center">
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                          <form className="row p-7">
                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="course"
                                className="required form-label mb-3"
                              >
                                Course
                              </label>
                              <Select
                                style={{ width: 160 }}
                                placeholder="Choose Course"
                                id="course"
                                optionFilterProp="label"
                                value={
                                  excdetails.ProgramName === "NULL"
                                    ? "Choose Course"
                                    : excdetails.ProgramName
                                }
                                onChange={(value) => {
                                  setExcDetails((prev) => ({
                                    ...prev,

                                    ProgramName: value,
                                  }));
                                  setProgId(value);
                                }}
                                options={courseOptions}
                              />
                            </div>

                            <div className="col-6 d-flex flex-column dropdown mt-3 ">
                              <label
                                htmlFor="branch"
                                className="required form-label mb-3"
                              >
                                Branch
                              </label>
                              <Select
                                style={{ width: 160 }}
                                placeholder="Choose Branch"
                                id="branch"
                                optionFilterProp="label"
                                value={
                                  excdetails.CourseName === "NULL"
                                    ? "Choose Branch"
                                    : excdetails.CourseName
                                }
                                onChange={(value) => {
                                  setExcDetails((prev) => ({
                                    ...prev,

                                    CourseName: value,
                                  }));
                                }}
                                options={branchOptions}
                              />
                            </div>

                            <div className="mt-3">
                              <input id="fileinput" type="file" />
                            </div>

                            <div className=" form-label mt-3">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleExcelUpload}
                              >
                                {uploadText}
                              </button>
                              {/* <p>{message}</p> */}
                              <input
                                id="fileinput"
                                type="file"
                                ref={fileInputRef} // Assign the ref to the file input
                                className="d-none"
                                style={{ display: "none" }} // Hide the file input
                                onChange={handleExcelUpload}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {getData.length > 0 ? (
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_customers_table"
                        >
                          <thead>
                            <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                              {/* <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  data-kt-check="true"
                                  data-kt-check-target="#kt_customers_table .form-check-input"
                                  value="1"
                                />
                              </div>
                            </th> */}
                              <th className="text-center min-w-70px px-5">
                                Actions
                              </th>
                              <th className="min-w-125px">Name</th>
                              <th className="min-w-125px">Mobile</th>
                              <th className="min-w-125px">Course</th>
                              <th className="min-w-125px">Branch</th>
                              <th className="min-w-125px">Qualification</th>
                              <th className="min-w-125px">Address</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {getData.map((item) => (
                              <tr key={item.regid}>
                                {/* <td>
                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="1"
                                  />
                                </div>
                              </td> */}
                                <td className="text-center">
                                  <button
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    className="btn btn-sm btn-primary btn-flex btn-center btn-active-light-primary"
                                    style={{
                                      backgroundColor: "#8096e0",
                                    }}
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </button>
                                  <div className="dropdown mt-3">
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasedit"
                                          aria-controls="offcanvasExample"
                                          onClick={() => handleEdit(item)}
                                        >
                                          Edit
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={() => handleDelete(item)}
                                        >
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <a className="text-gray-800 text-hover-primary mb-1">
                                    {item.FullName}
                                  </a>
                                </td>
                                <td>{item.Mobile}</td>
                                <td>{item.ProgramName}</td>
                                <td>{item.CourseName}</td>
                                {/* <td>
                              <div className="badge badge-light-danger">
                                Locked
                              </div>
                            </td> */}
                                <td>{item.EmpQualification}</td>
                                <td>{item.Address}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center">
                      {errMsg ? errMsg : "No Records Found"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Employees;
