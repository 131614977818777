import React, { useState, useEffect } from "react";
import { Select } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "./Pagination";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { API_BASE_LB_URL } from "../Config";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

const BooksReport = (props) => {
  const [getData, setGetData] = useState([]);
  const [course, setCategory] = useState([]);
  const [bookType, setBookType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedBookType, setSelectedBookType] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const navigate = useNavigate();

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExcelDownload = () => {
    // Get table element
    const filteredData = getData.map((record, index) => ({
      "Sl.No": index + 1,

      Name: record.BookName,
      Author: record.Author,
      Publisher: record.Publisher,
      Category: record.CategoryName,
      Type: record.BookType,
    }));

    // Convert table to workbook
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllRecords");

    // Write the workbook to a file
    XLSX.writeFile(wb, "booksreport.xlsx");
  };

  const handlePdfDownload = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    const tableData = getData.map((item, index) => [
      index + 1,
      item.BookName,
      item.Author,
      item.Publisher,
      item.CategoryName,
      item.BookType,
    ]);

    const headers = [
      "Sl.No",
      "Name",
      "Author",
      "Publisher",
      "Category",
      "Type",
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      columnStyles: {
        0: { cellWidth: "auto", halign: "center" },
        1: { cellWidth: "auto", halign: "center" },
        2: { cellWidth: "auto", halign: "center" },
        3: { cellWidth: "auto", halign: "center" },
        4: { cellWidth: "auto", halign: "center" },
        5: { cellWidth: "auto", halign: "center" },
      },
      headStyles: {
        fillColor: [224, 224, 224], // Header background color Grey background color
        textColor: [0, 0, 0], // Black text color
        halign: "center",
      },
    });

    doc.save("booksreport.pdf");
    // const opt = {
    //   margin: 0.3,
    //   filename: "myfile.pdf",
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: { scale: 2.0 },
    //   jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    // };

    // html2pdf().set(opt).from(element).save();
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}Reports/BookReport?OrgId=${orgId}&BookCatId=${selectedCategory}&BookTypeId=${selectedBookType}&SearchText=${searchInput}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setGetData(result);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const courseOptions = course.map((course) => ({
    value: course.Id,
    label: course.Name,
  }));

  const typeOptions = bookType.map((type) => ({
    value: type.Id,
    label: type.Name,
  }));

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    //FOR GETTING STUDENT DATA FROM DATABASE
    setLoading(true);
    const getDataFromDB = async () => {
      try {
        const response = await fetch(
          `${API_BASE_LB_URL}Reports/BookReport?OrgId=${orgId}&BookCatId=0&BookTypeId=0&SearchText=`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();

        setGetData(result);
        console.log(getData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const getCourse = async () => {
      try {
        const response = await fetch(
          `${API_BASE_LB_URL}bookmaster/getbookcategories?OrgId=${orgId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();

        setCategory(result);
      } catch (error) {
        console.log(error);
      }
    };

    const getBookType = async () => {
      if (orgId) {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookmaster/GetBookTypes?OrgId=${orgId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();

          setBookType(result.ResultData);
        } catch (error) {
          console.log(error);
        }
      }
    };

    // Call the async function
    getDataFromDB();
    getCourse();
    getBookType();
  }, [navigate, orgId]);

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Book Report
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row w-800px">
                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="category"
                        >
                          Category
                        </label>
                        <Select
                          style={{ width: 160 }}
                          placeholder="Choose Course"
                          id="course"
                          optionFilterProp="label"
                          value={selectedCategory || null}
                          onChange={(value) => {
                            setSelectedCategory(value);
                          }}
                          options={courseOptions}
                        />
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="booktype"
                        >
                          Book Type
                        </label>
                        <Select
                          style={{ width: 160 }}
                          placeholder="Choose Type"
                          id="course"
                          optionFilterProp="label"
                          value={selectedBookType || null}
                          onChange={(value) => {
                            setSelectedBookType(value);
                          }}
                          options={typeOptions}
                        />
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="bookname"
                        >
                          Book Name/Author
                        </label>
                        <div className="d-flex align-items-center position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            id="bookname"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-180px ps-13"
                            placeholder="Search with Name "
                            value={"" || searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                          onClick={handleSearch}
                        >
                          Search
                          {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3 px-4"
                          onClick={handlePdfDownload}
                        >
                          <i className="fa-solid fa-file-pdf fs-2"></i>
                        </button>
                      </div>
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          onClick={handleExcelDownload}
                          data-bs-target="#kt_customers_export_modal"
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start fw-bold fs-7 text-uppercase gs-0 tableheader">
                            <th className="text-center min-w-125px">Sl.No</th>
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-125px">Author</th>
                            <th className="min-w-125px">Publisher</th>
                            <th className="min-w-125px">Category</th>
                            <th className="min-w-125px">Type</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {currentItems.map((item, index) => (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td>
                                <a className="text-gray-800 text-hover-primary mb-1">
                                  {item.BookName}
                                </a>
                              </td>
                              <td>{item.Author}</td>
                              <td>{item.Publisher}</td>
                              <td>{item.CategoryName}</td>
                              <td>{item.BookType}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={getData.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BooksReport;
