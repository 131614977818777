import React, { useState, useRef, useEffect } from "react";
import JsBarcode from "jsbarcode";
import { API_BASE_LB_URL } from "../Config";
import Header from "./Header";
import Footer from "./Footer";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";

const Barcode = (props) => {
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [bookInvId, setBookInvId] = useState(null);
  const [bookData, setBookData] = useState([]);
  const [labeldata, setLabelData] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const barcodeRef = useRef("");
  const navigate = useNavigate();

  const generateBarcode = () => {
    const combinedValue = ` ${inputValue2}`; // Combine the inputs with a space
    if (barcodeRef.current && combinedValue) {
      JsBarcode(barcodeRef.current, combinedValue, {
        format: "CODE128", // You can change the format as needed
        lineColor: "#000000",
        width: 1,
        height: 50,
        displayValue: false,
      });
      // Open a new window for printing
      const printWindow = window.open("", "", "height=600,width=800");
      printWindow.document.write(
        "<html><head><title>Print Barcode</title></head><body>"
      );
      printWindow.document.write("<div style='text-align: center; '>"); // Center align the content
      // printWindow.document.write("<div>"); // Wrap the barcode SVG in a div
      printWindow.document.write(barcodeRef.current.outerHTML); // Insert the barcode SVG HTML
      // Insert the input value below the barcode
      printWindow.document.write(`<p>${inputValue2}</p>`);
      printWindow.document.write("</div>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  };
  const handleSelectBook = (value) => {
    setInputValue1(value); // Update the state with the selected value
    setBookInvId(value);
    setInputValue2("");
    console.log(bookInvId);
  };

  const handleSelectLabel = (value) => {
    const selectedLabel = labeldata.find((label) => label.labelId === value);

    if (selectedLabel) {
      console.log(selectedLabel.LabelName); // Output the label name
      setInputValue2(selectedLabel.LabelName); // Output the label name
    }
  };
  const bookOptions = bookData.map((book) => ({
    value: book.BookInventoryId,
    label: book.Name,
  }));

  const labelOptions = labeldata.map((label) => ({
    value: label.labelId,
    label: label.LabelName,
  }));

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          setOrgId(orgId);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (orgId) {
      const getBookFromDB = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}barcode/getbook?OrgId=${orgId}`
          );
          if (!response.ok) {
            setErrMsg("Network Error");
          }
          const result = await response.json();

          setBookData(result);
        } catch (error) {
          setErrMsg("Network Error");
        } finally {
          setLoading(false); // Set loading to false once the data is fetched or if an error occurs
        }
      };

      const getLabels = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}barcode/getbooklabel?OrgId=${orgId}&InventoryId=${bookInvId}`
          );
          if (!response.ok) {
            setErrMsg("Network Error");
          }
          const result = await response.json();

          setLabelData(result);
        } catch (error) {
          setErrMsg("Network Error");
        } finally {
          setLoading(false); // Set loading to false once the data is fetched or if an error occurs
        }
      };

      getBookFromDB();
      getLabels();
    }
  }, [orgId, bookInvId]);

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-2" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Barcode Generation
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl "
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6 pb-6">
                    <div className="card-title row w-800px">
                      <div className="col-12 col-md-4 d-flex flex-column dropdown mt-3 ">
                        <label
                          htmlFor="selectbook"
                          className="required form-label mb-3"
                        >
                          Select Book
                        </label>
                        <Select
                          style={{ width: 250 }}
                          showSearch
                          placeholder="Choose Book"
                          id="selectbook"
                          optionFilterProp="label"
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          value={inputValue1 || null}
                          onChange={handleSelectBook}
                          options={bookOptions}
                        />
                      </div>

                      <div className="col-12 col-md-4 d-flex flex-column dropdown mt-3  ml-10">
                        <label
                          htmlFor="selectlabel"
                          className="required form-label mb-3"
                        >
                          Select Labels
                        </label>
                        <Select
                          style={{ width: 250 }}
                          placeholder="Choose Labels"
                          id="selectlabel"
                          optionFilterProp="label"
                          value={inputValue2 || null}
                          onChange={handleSelectLabel}
                          options={labelOptions}
                        />
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          className="btn btn-primary me-3 mt-8"
                          onClick={generateBarcode}
                        >
                          Print
                        </button>
                      </div>
                    </div>
                    {/* <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      >
                        <div className="me-3">
                          <select
                            className="form-select form-select-solid  w-100px"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Status"
                            data-kt-ecommerce-order-filter="status"
                          >
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="locked">Locked</option>
                          </select>
                        </div>

                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Export
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <svg className="d-none" ref={barcodeRef}></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Barcode;
