import React, { useState, useEffect } from "react";
import { Select } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "./Pagination";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { API_BASE_LB_URL } from "../Config";
import { useNavigate } from "react-router-dom";
const EmployeesReports = (props) => {
  const [getData, setGetData] = useState([]);
  const [searchinput, setSearchInput] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log(searchinput, mobile);
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}Reports/EmployeeReport?FullName='${searchinput}'&OrgId=${orgId}&Mobile='${mobile}'`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setGetData(result);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePdfDownload = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    const tableData = getData.map((item, index) => [
      index + 1,
      item.FullName,
      item.Mobile,
      item.EmpQualification,
      item.Address,
    ]);

    // Add headers
    const headers = ["Sl.No", "Name", "Mobile", "Qualification", "Address"];

    doc.autoTable({
      head: [headers],
      body: tableData,
      columnStyles: {
        0: { cellWidth: "auto", halign: "center" }, // Name
        1: { cellWidth: "auto", halign: "center" }, // Email
        2: { cellWidth: "auto", halign: "center" }, // Phone
        3: { cellWidth: "auto", halign: "center" }, // Address
        4: { cellWidth: "auto", halign: "center" }, // Address
      },
      headStyles: {
        fillColor: [224, 224, 224], // Header background color Grey background color
        textColor: [0, 0, 0], // Black text color
        halign: "center",
      },
    });

    // Save PDF
    doc.save("employeesreport.pdf");
  };

  const handleExcelDownload = () => {
    // Assuming `allRecords` contains all the data you want to export
    const filteredData = getData.map((record, index) => ({
      "Sl.No": index + 1,
      Name: record.FullName,
      Mobile: record.Mobile,
      Qualification: record.EmpQualification,
      Adress: record.Address,
    }));

    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllRecords");

    // Write the workbook to a file
    XLSX.writeFile(wb, "employeesreport.xlsx");
  };

  // const handleExcelDownload = () => {
  //   // Get table element
  //   const table = document.getElementById("kt_customers_table");

  //   // Convert table to workbook
  //   const ws = XLSX.utils.table_to_sheet(table);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // Write the workbook to a file
  //   XLSX.writeFile(wb, "employeesreport.xlsx");
  // };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    //FOR GETTING STUDENT DATA FROM DATABASE
    setLoading(true);
    const getDataFromDB = async () => {
      try {
        const response = await fetch(
          `${API_BASE_LB_URL}Reports/EmployeeReport?FullName=NULL&OrgId=${orgId}&Mobile=NULL`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();

        setGetData(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    // Call the async function
    getDataFromDB();
  }, [orgId]);

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-2" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Employees Report
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl "
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row w-800px">
                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-1 p-0">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="name"
                        >
                          Name
                        </label>
                        <div className="d-flex align-items-center position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            id="name"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-200px ps-13"
                            placeholder="Search with Name "
                            style={{ width: 250 }}
                            value={searchinput || ""}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-startmy-1 mx-1 p-0">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="mobile"
                        >
                          Mobile
                        </label>
                        <div className="d-flex align-items-center position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            id="mobile"
                            placeholder="Search with Mobile"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-lg-200px w-180px ps-13"
                            value={mobile || ""}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                          onClick={handleSearch}
                        >
                          Search
                          {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3 px-4"
                          onClick={handlePdfDownload}
                        >
                          <i className="fa-solid fa-file-pdf fs-2"></i>
                        </button>
                      </div>
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          onClick={handleExcelDownload}
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Export
                        </button>
                      </div>
                      <div
                        className="d-flex justify-content-end align-items-center d-none"
                        data-kt-customer-table-toolbar="selected"
                      >
                        <div className="fw-bold me-5">
                          <span
                            className="me-2"
                            data-kt-customer-table-select="selected_count"
                          ></span>
                          Selected
                        </div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-kt-customer-table-select="delete_selected"
                        >
                          Delete Selected
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="dt-container dt-bootstrap5 dt-empty-footer">
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_customers_table"
                        >
                          <thead>
                            <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                              <th className="text-center min-w-125px">Sl.No</th>
                              <th className="min-w-125px dt-orderable-asc dt-orderable-desc">
                                Name
                              </th>
                              {/* <th className="min-w-125px">Status</th> */}
                              <th className="min-w-125px">Mobile</th>

                              <th className="min-w-125px">Qualification </th>
                              <th className="min-w-125px">Address </th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {currentItems.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>

                                <td>
                                  <a className="text-gray-600 text-hover-primary mb-1">
                                    {item.FullName}
                                  </a>
                                </td>

                                <td>{item.Mobile}</td>
                                <td>{item.EmpQualification}</td>
                                <td>{item.Address}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={getData.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmployeesReports;
