import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Dashboard from "./components/Dashboard";
import Students from "./components/Students";
import StudentsReports from "./components/StudentsReports";
import BooksReport from "./components/BooksReport";
import Employees from "./components/Employees";
import EmployeesReports from "./components/EmployeesReport";
import BookTitles from "./components/BookTitles";
import BookLabels from "./components/BookLabels";
import GenerateLabels from "./components/GenerateLabels";
import DueBooks from "./components/DueBooks";
import DueBooksReports from "./components/DueBooksReports";
import ReturnBooksReport from "./components/ReturnBooksReport";
import IssueBooks from "./components/IssueBooks";
import IssueBooksReport from "./components/IssueBooksReport";
import DailyReport from "./components/DailyReport";
import ReturnBook from "./components/ReturnBook";
import Racks from "./components/Racks";
import Transactions from "./components/Transactions";
import Tabs from "./components/Tabs";
import Loginpage from "./components/Loginpage";
import Barcode from "./components/Barcode";
// import Tabs from "./components/Tabs";
import "./App.css";
import ProtectedRoute from "./components/protectedroute";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <Routes>
        <Route path="/" element={<Loginpage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              {" "}
              <Dashboard />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/students"
          element={
            <ProtectedRoute>
              <Students />
            </ProtectedRoute>
          }
        />
        <Route path="/studentsreports" element={<StudentsReports />} />
        <Route path="/booksreport" element={<BooksReport />} />
        <Route
          path="/employees"
          element={
            <ProtectedRoute>
              <Employees />
            </ProtectedRoute>
          }
        />
        <Route path="/employeesreports" element={<EmployeesReports />} />
        <Route
          path="/booktitles"
          element={
            <ProtectedRoute>
              <BookTitles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booklabels"
          element={
            <ProtectedRoute>
              <BookLabels />
            </ProtectedRoute>
          }
        />
        <Route path="/generatelabels" element={<GenerateLabels />} />
        <Route path="/issuebooksreport" element={<IssueBooksReport />} />
        <Route path="/issuebooks" element={<IssueBooks />} />
        <Route path="/duebooks" element={<DueBooks />} />
        <Route path="/returnbooksreport" element={<ReturnBooksReport />} />
        <Route path="/duebooksreport" element={<DueBooksReports />} />
        <Route path="/dailyreport" element={<DailyReport />} />
        <Route path="/racks" element={<Racks />} />
        <Route path="/persontransaction" element={<Transactions />} />
        <Route path="/returnbook" element={<ReturnBook />} />
        {/* <Route path="/tabs" element={<Tabs />} /> */}
        <Route path="/barcode" element={<Barcode />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// function ScrollToTop() {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }
