import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { API_BASE_LB_URL } from "../Config";
import { useNavigate } from "react-router-dom";
import { useLabelContext } from "../context";
import Pagination from "./Pagination";
import Swal from "sweetalert2";

const DueBooks = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("Loading...");
  const [searchInput, setSearchInput] = useState("");
  const [rowData, setRowdata] = useState([]);
  const [returnDetails, setReturnDetails] = useState({
    comment: "",
    return_date: "",
  });
  const [orgId, setOrgId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const [errMsg, setErrMsg] = useState("Loading...");
  const navigate = useNavigate();
  const { setReturnBooks } = useLabelContext();
  // const [rowdata, setRowdata] = useState([]);

  //pagination

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //FORMATTING DATE IN EDIT STUDENT DETAILS
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    // Ensure the date is valid
    if (isNaN(date.getTime())) return "";

    // Format to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}duebook/getduebook?OrgId=${orgId}&SearchText='${searchInput}'`
      );
      if (!response.ok) {
        setData([]);
        setError("No Records Found");
      }
      const result = await response.json();
      setData(result);
      setCurrentPage(1);
      // setData(limitedResult);
    } catch (error) {
      setError("No Records Found");
    } finally {
      setLoading(false);
    }
  };

  const handleReturn = async (item) => {
    console.log(item);
    setRowdata(item);
    // try {
    //   // Make a POST request to update data
    //   const response = await fetch(
    //     `${API_BASE_LB_URL}duebook/GetIssuedBooksWithFine?OrgId=21001&regId=${item.RegId}`
    //   );
    //   const result = await response.json();
    //   setReturnBooks(result);

    //   // setRowdata(result);
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }
    // } catch (error) {
    //   console.error("Error updating data:", error);
    // }
  };

  const handlecancel = () => {
    setReturnDetails({
      comment: "",
      return_date: "",
    });
  };

  const handleSubmit = async () => {
    console.log(returnDetails);
    if (!returnDetails.return_date) {
      alert("Selct Return Date");
      return;
    }
    const requestData = {
      OrgId: orgId,
      Id: rowData.RentalId,
      ReturnDate: returnDetails.return_date,
      ReturnComment: "" || returnDetails.comment,
      Fine: rowData.CAluclatedFine,
      UpdatedBy: updatedId,
    };
    console.log(requestData);
    try {
      // Make a POST request to update data
      const response = await fetch(`${API_BASE_LB_URL}duebook/ReturnBook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const responseBody = await response.json();
      console.log(responseBody);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.reload();
          }
        });
        const updatedData = data.filter(
          (each) => each.labelId !== rowData.labelId
        );
        setData(updatedData);
        setReturnDetails({
          comment: "",
          return_date: "",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
          setUpdatedId(UpdatedById);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_LB_URL}duebook/getduebook?OrgId=${orgId}&SearchText=NULL`
        );
        if (response.ok) {
          const result = await response.json();
          setData(result);
        }
      } catch (error) {
        // setError("No Records Found");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, orgId, updatedId]);
  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Due Books
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          data-kt-customer-table-filter="search"
                          className="form-control form-control-solid w-250px ps-13"
                          placeholder="Search with Issued to,HallTicket,Label or Book Name"
                          value={"" || searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-2"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      ></div>
                    </div>
                  </div>
                  {data.length > 0 ? (
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_customers_table"
                        >
                          <thead>
                            <tr className="text-start fw-bold fs-7 text-uppercase gs-0 tableheader">
                              <th className="text-start  min-w-70px px-5">
                                Actions
                              </th>
                              <th className="min-w-125px">ISSUED TO</th>
                              <th className="min-w-110px">
                                HallTicket/Admission
                              </th>
                              <th className="min-w-110px">Label</th>
                              <th className="min-w-125px">Name</th>
                              <th className="min-w-125px">Author</th>
                              <th className="min-w-125px">Issue Date</th>
                              <th className="min-w-125px">Due Date</th>
                              <th className="min-w-125px">Comment</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {currentItems.map((item, index) => (
                              <tr key={index}>
                                <td className="text-end">
                                  {/* <Link to="/returnbook"> */}
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-flex btn-center btn-active-light-primary"
                                    style={{
                                      backgroundColor: "#8096e0",
                                    }}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasedit"
                                    aria-controls="offcanvasExample"
                                    onClick={() => handleReturn(item)}
                                  >
                                    Return
                                  </button>
                                  {/* </Link> */}
                                </td>
                                <td>{item.FullName}</td>
                                <td>{item.HallTicketNo}</td>
                                <td>{item.BookLabel}</td>
                                <td>{item.Book}</td>
                                <td>{item.Author}</td>
                                <td>{formatDate(item.IssueDate)}</td>
                                <td>{formatDate(item.DueDate)}</td>
                                <td>{item.IssueComment}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={data.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  ) : (
                    <p className="text-center">
                      {error ? error : "No Records Found"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasedit"
        aria-labelledby="offcanvasExampleLabel"
        style={{ width: "500px" }}
      >
        <div className="offcanvas-header bg-secondary">
          <h5 className="offcanvas-title " id="offcanvasExampleLabel">
            Return Book
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <form className="row p-7">
          <div className="col-12 col-md-6 mb-5 fv-row">
            <label htmlFor="firstname" className="required form-label mb-3">
              Fine
            </label>
            <input
              required
              type="text"
              className="form-control form-control-sm form-control-solid"
              // name="campaign_name"
              placeholder=""
              id="firstname"
              name="comment"
              value={rowData.CAluclatedFine || ""}
              readOnly
            />
          </div>

          <div className="col-6 d-flex flex-column dropdown ">
            <label htmlFor="date" className="required form-label mb-3">
              Return Date
            </label>
            <input
              required
              type="date"
              style={{ width: 160 }}
              className="form-control form-control-sm form-control-solid"
              placeholder=""
              id="date"
              name="return_date"
              value={returnDetails.return_date || ""}
              onChange={(e) => {
                setReturnDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </div>

          <div className="col-12 col-md-6 mb-5 fv-row">
            <label htmlFor="firstname" className=" form-label mb-3">
              Comment
            </label>
            <input
              type="text"
              className="form-control form-control-sm form-control-solid"
              // name="campaign_name"
              placeholder=""
              id="firstname"
              name="comment"
              value={returnDetails.comment || ""}
              onChange={(e) => {
                setReturnDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </div>

          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              data-bs-dismiss="offcanvas"
              className="btn btn-danger ms-2"
              onClick={handlecancel}
            >
              Cancel
            </button>
            {/* <p>{message}</p> */}
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default DueBooks;
