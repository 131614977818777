import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { API_BASE_LB_URL } from "../Config";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import Swal from "sweetalert2";

const IssueBooks = (props) => {
  const [NamesearchText, setNameSearchText] = useState("");
  const [BooksearchText, setBookSearchText] = useState("");
  const [data, setData] = useState([]);
  const [NameFilteredData, setNameFilteredData] = useState([]);
  const [Bookdata, setBookData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null); // Store the selected student
  const [BookfilteredData, setBookFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dueddate, setDueddate] = useState(null);
  const [comment, setComment] = useState(null);
  const [error, setError] = useState(null);
  const [orgId, setOrgId] = useState(null); // State to store
  const [updatedId, setUpdatedId] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [bookerrMsg, setBookErrMsg] = useState(null);
  const navigate = useNavigate(); // Hook to navigate programmatically

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
          setUpdatedId(UpdatedById);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate, updatedId]);

  const handleSearchChange = (event) => {
    setNameSearchText(event.target.value);
  };

  const handleBooksearchChange = (event) => {
    setBookSearchText(event.target.value);
  };

  // Fetch students based on search text
  // const handleNameSearchClick = () => {
  //   if (!orgId) return; // Prevent API call if orgId is not set
  //   setLoading(true);
  //   const url = `${API_BASE_LB_URL}bookrent/getstudentsddl?OrgId=${orgId}&SearchText=NULL`;
  //   fetch(url)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setData(data);
  //       const filterbyname = data.filter(
  //         (item) =>
  //           (item.FullName &&
  //             item.FullName.toLowerCase().includes(
  //               NamesearchText.toLowerCase()
  //             )) ||
  //           (item.HallTicketNo &&
  //             item.HallTicketNo.toLowerCase().includes(
  //               NamesearchText.toLowerCase()
  //             ))
  //       );
  //       setNameFilteredData(filterbyname);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       setLoading(false);
  //     });
  // };
  const handleNameSearchClick = async () => {
    if (!orgId) return; // Prevent API call if orgId is not set
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}bookrent/getstudentsddl?OrgId=${orgId}&SearchText='${NamesearchText}'`
      );
      if (!response.ok) {
        setData([]);
        setErrMsg("No Records Found");
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setErrMsg("No Records Found");
    } finally {
      setLoading(false);
    }
  };
  // Fetch books based on search text
  // const handleBookSearchClick = () => {
  //   if (!orgId) return; // Prevent API call if orgId is not set
  //   setLoading(true);
  //   const url = `${API_BASE_LB_URL}bookrent/getbooksddl?OrgId=${orgId}&SearchText=NULL`;
  //   fetch(url)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setBookData(data);
  //       const filterbybook = data.filter(
  //         (item) =>
  //           item.BookName.toLowerCase().includes(
  //             BooksearchText.toLowerCase()
  //           ) ||
  //           item.BookLabel.toLowerCase().includes(BooksearchText.toLowerCase())
  //       );
  //       setBookFilteredData(filterbybook);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       setLoading(false);
  //     });
  // };

  const handleBookSearchClick = async () => {
    if (!orgId) return; // Prevent API call if orgId is not set
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}bookrent/getbooksddl?OrgId=${orgId}&SearchText='${BooksearchText}'`
      );
      if (!response.ok) {
        setBookData([]);
        setBookErrMsg("No Records Found");
      }
      const result = await response.json();
      setBookData(result);
    } catch (error) {
      setBookErrMsg("No Records Found");
    } finally {
      setLoading(false);
    }
  };

  // Handle student selection
  const handlePersonSelect = (person) => {
    setSelectedPerson(person);
  };

  // Handle book issuance
  const handleIssue = async (book) => {
    if (!selectedPerson) {
      alert("Please select a person first.");
      return;
    }

    if (!dueddate) {
      alert("Please select due data");
      return;
    }
    // const note = document.querySelector(`#note-${book.bookid}`).value;
    // const dueDate = document.querySelector(`#dueDate-${book.bookid}`).value;

    const postData = {
      RegId: selectedPerson.regId,
      bookId: book.BookId,
      bookName: book.BookName,
      accessNo: book.BookLabel,
      bookLabelId: book.BookLabelId,
      dueDate: dueddate,
      OrgId: orgId,
      TransactionId: 4501,
      IssueDate: new Date().toISOString().split("T")[0],
      IssuedBy: updatedId,
      IssueComment: comment ? comment : "",
      Status: 1,
      CreatedBy: updatedId,
      IsActive: 1,
    };
    console.log("Sending data to server:", postData);

    try {
      const response = await axios.post(
        `${API_BASE_LB_URL}bookrent/IssueBook`,
        postData
      );
      console.log(response);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.reload();
          }
        });
      }
      console.log(response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }

    // Optional: Store issued data in local storage
    const issuedData = {
      person: selectedPerson,
      book: book,
    };
    console.log("Issued Data:", issuedData);
    localStorage.setItem("issuedData", JSON.stringify(issuedData));
  };

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Issue Books
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-body pt-6">
                    <div className="table-responsive   d-flex flex-row row">
                      {/* Student Search Table */}
                      <div className="col-12 col-lg-5">
                        <div className="d-flex align-items-center position-relative my-1 mx-3">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-lg-120 w-md-250px ps-13"
                            placeholder="HallTicket/Admission or Name or Mobile"
                            value={NamesearchText}
                            onChange={handleSearchChange}
                          />
                          <button
                            type="button"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-2"
                            onClick={handleNameSearchClick}
                          >
                            Search
                          </button>
                        </div>
                        {data.length > 0 ? (
                          <table
                            className="table table-row-dashed fs-6 gy-5 border mx-2 "
                            id="kt_customers_table"
                          >
                            <thead>
                              <tr className="text-start fw-bold fs-7 text-uppercase gs-0 tableheader">
                                <th className="w-10px pe-2">
                                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3 ms-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      data-kt-check="true"
                                      data-kt-check-target="#kt_customers_table .form-check-input"
                                      value="1"
                                    />
                                  </div>
                                </th>
                                <th className="min-w-125px">Name</th>
                                <th className="min-w-125px">
                                  HallTicket/Admission
                                </th>
                              </tr>
                            </thead>

                            <tbody className="fw-semibold text-gray-600 gs-0 ">
                              {data?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid ms-1">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={item.regid}
                                        onChange={() =>
                                          handlePersonSelect(item)
                                        }
                                        // checked={selectedPerson.includes(item.regid)}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a className="text-gray-800 text-hover-primary mb-1">
                                      {item.FullName}
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      className="text-gray-600 text-hover-primary mb-1"
                                    >
                                      {item.HallTicketNo}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p className="text-center py-1">{errMsg}</p>
                        )}
                      </div>
                      {/* Book Search Table */}
                      <div className="col-12 col-lg-7">
                        <div className="d-flex align-items-center position-relative my-1 mx-3">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-lg-120 w-md-250px ps-13"
                            placeholder="Book Label/Book Name"
                            value={BooksearchText}
                            onChange={handleBooksearchChange}
                          />
                          <button
                            type="button"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-5"
                            onClick={handleBookSearchClick}
                          >
                            Search
                          </button>
                        </div>
                        {Bookdata.length > 0 ? (
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-5 border mx-2"
                            id="kt_customers_table"
                          >
                            <thead>
                              <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                                <th className="min-w-125px px-8">Actions</th>
                                <th className="min-w-125px">Name</th>
                                <th className="min-w-125px">Access No</th>
                                <th className="min-w-125px">Note</th>
                                <th className="min-w-125px">Due Date</th>
                              </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                              {Bookdata.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-sm btn-primary btn-flex btn-center btn-active-light-primary"
                                      style={{
                                        backgroundColor: "#8096e0",
                                      }}
                                      data-kt-menu-trigger="click"
                                      data-kt-menu-placement="bottom-start"
                                      onClick={() => handleIssue(item)}
                                    >
                                      Issue
                                    </button>
                                  </td>
                                  <td>
                                    <a className="text-gray-800 text-hover-primary mb-1">
                                      {item.BookName}
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      className="text-gray-600 text-hover-primary mb-1"
                                    >
                                      {item.BookLabel}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="badge ">
                                      <input
                                        type="text"
                                        id={`note-${item.bookid}`}
                                        onChange={(e) =>
                                          setComment(e.target.value)
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="badge ">
                                      <input
                                        type="date"
                                        id={`dueDate-${item.bookid}`}
                                        onChange={(e) =>
                                          setDueddate(e.target.value)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p className="text-center">{bookerrMsg}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IssueBooks;
