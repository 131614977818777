import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_LB_URL } from "../Config";

const Loginpage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Sign In");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (userDetails) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setBtnText("Signing In....");
    try {
      const url = `${API_BASE_LB_URL}dashboard/Login?username=${username}&password=${password}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      // console.log(data, "Response received");

      if (data.Status) {
        sessionStorage.setItem("userDetails", JSON.stringify(data.ResultData));
        // console.log("Login successful", data.ResultData);
        navigate("/dashboard"); // Navigate to dashboard after successful login
      } else {
        setError(data.error || "Invalid username or password.");
        alert("Invalid Credentials");
        setBtnText("Sign In");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-lg-500px p-10">
              <form
                onSubmit={handleSubmit}
                className="form w-100"
                noValidate="novalidate"
                id="kt_sign_in_form"
                data-kt-redirect-url="index.html"
                action="#"
              >
                <div className="text-center mb-11">
                  <h1 className="text-gray-900 fw-bolder mb-3">
                    Sign In to Start Your Session
                  </h1>
                </div>

                <div className="fv-row mb-8">
                  <input
                    type="text"
                    placeholder="Username"
                    name="username"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="fv-row mb-3  p-0 d-flex align-items-center">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <i
                    className={`fa-regular ${
                      isPasswordVisible ? "fa-eye-slash " : "fa-eye"
                    } align-center`}
                    
                  ></i> */}
                </div>
                <div className="fv-row mb-3  p-0 d-flex align-items-center">
                  <input
                    id="showpassword"
                    type="checkbox"
                    autoComplete="off"
                    checked={isPasswordVisible}
                    onChange={() =>
                      setIsPasswordVisible((prevState) => !prevState)
                    }
                  />
                  <label htmlFor="showpassword" className="mx-2">
                    Show Password
                  </label>
                </div>

                {/* {error && <div className="alert alert-danger">{error}</div>} */}

                <div className="d-grid mb-10">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    <span className="indicator-label">{btnText}</span>
                    {loading && (
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          style={{
            backgroundImage: "url('assets/media/misc/auth-bg.png')",
            overflow: "hidden",
          }}
        >
          <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            {/* <a href="index.html" className="mb-0 mb-lg-12"> */}
            <img
              alt="Logo"
              src="https://i.imghippo.com/files/ooS0i1726053224.webp"
              className="h-60px h-lg-75px"
            />
            {/* </a> */}
            <img
              className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src="assets/media/misc/auth-screens.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
