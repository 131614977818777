import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { API_BASE_LB_URL } from "../Config";

import { Link } from "react-router-dom";

const colors = [
  "#20c997",
  "#6610f2",
  "#6f42c1",
  "#000000",
  "#ffc107",
  "#fd7e14",
];
function Dashboard() {
  const [count, setCount] = useState([]);
  const [issuebooksData, setIssueBooksData] = useState([]);
  const [dueBooksData, setDueBooksData] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        setUserDetails(parsedUserDetails);
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (userDetails && userDetails.length > 0) {
      const orgId = userDetails[0]?.OrgId;
      if (orgId) {
        const getCountFromDB = async () => {
          try {
            const response = await fetch(
              `${API_BASE_LB_URL}dashboard/DashboardCounts?OrgId=${orgId}`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const result = await response.json();
            setCount(result.ResultData);
          } catch (error) {
            console.log(error);
          }
        };

        const getIssueBooksData = async () => {
          try {
            const response = await fetch(
              `${API_BASE_LB_URL}dashboard/getbooksddl?OrgId=${orgId}&Type=0&SearchText=NULL`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const result = await response.json();
            const limitedResult = result.slice(0, 6);
            setIssueBooksData(limitedResult);
          } catch (error) {
            console.log(error);
          }
        };

        const getDueBooksData = async () => {
          try {
            const response = await fetch(
              `${API_BASE_LB_URL}dashboard/getduebook?OrgId=${orgId}&Type=0&SearchText=NULL`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const result = await response.json();
            const limitedResult = result.slice(0, 6);
            setDueBooksData(limitedResult);
          } catch (error) {
            console.log(error);
          }
        };

        getCountFromDB();
        getIssueBooksData();
        getDueBooksData();
      } else {
        console.error("OrgId is not available in userDetails");
      }
    }
  }, [userDetails]);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
            <div
              id="kt_toolbar_container"
              className="container-xxl d-flex flex-stack flex-wrap"
            >
              <div className="page-title d-flex flex-column">
                <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                  Library Dashboard
                  <span className="page-desc text-muted fs-7 fw-semibold pt-1"></span>
                </h1>
              </div>
              {/*  <Link
                to="/racks"
                className="btn bg-body d-flex flex-center h-35px h-lg-40px"
              >
                Racks
              </Link> */}
            </div>
          </div>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-xxl pt-4"
          >
            <div className="content flex-row-fluid pt-0 pb-0" id="kt_content">
              <div className="row gx-5 gx-xl-10">
                <div className="col-xl-4 " style={{ height: 519 }}>
                  <div className="card card-flush h-xl-100">
                    <div
                      className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px"
                      style={{
                        backgroundImage:
                          " url('assets/media/svg/shapes/top-green.png')",
                      }}
                      data-bs-theme="light"
                    >
                      <h3 className="card-title align-items-start flex-column text-white pt-15">
                        <span className="fw-bold fs-2x mb-3">My Tasks</span>
                      </h3>
                    </div>
                    <div className="card-body mt-n20 ">
                      <div className="mt-n20 position-relative">
                        <div className="row g-3 g-lg-6">
                          <div className="col-6">
                            <Link to="/students">
                              <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <i className="bi bi-people-fill fs-1 text-primary">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                    {count.map(
                                      (item) =>
                                        item.RecType === "Student" && item.Count
                                    )}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Students
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6">
                            <Link to="/employees">
                              <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <i className="fa-solid fa-people-group fs-1 text-primary">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                    {count.map(
                                      (item) =>
                                        item.RecType === "Staff" && item.Count
                                    )}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Employees
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6">
                            <Link to="/booktitles">
                              <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <i className="fa-solid fa-book fs-1 text-primary">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                    {count.map(
                                      (item) =>
                                        item.RecType === "Books" && item.Count
                                    )}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Book Titles
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-6">
                            <Link to="/booklabels">
                              <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <i className="fa-solid fa-bookmark fs-1 text-primary">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                    {count.map(
                                      (item) =>
                                        item.RecType === "BookLabels" &&
                                        item.Count
                                    )}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Book Labels
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 mb-10">
                  <div className="row g-5 g-xl-10">
                    <div className="col-xl-6 mb-xl-10 mt-xl-10">
                      <div
                        id="kt_sliders_widget_1_slider"
                        className="card carousel carousel-custom carousel-stretch slide h-xl-100"
                        data-bs-ride="carousel"
                        data-bs-interval="5000"
                      >
                        <div className="card-header pt-5">
                          <a>
                            <h4 className="card-title d-flex align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Issued Books
                              </span>
                              <span className="text-gray-500 mt-1 fw-bold fs-7">
                                Issued &nbsp;
                                <span>
                                  {count.map(
                                    (item) =>
                                      item.RecType === "IssuedCount" &&
                                      item.Count
                                  )}
                                </span>
                                &nbsp;books
                              </span>
                            </h4>
                          </a>
                          <div
                            className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            {/* <Link to="/issuebooksreport">
                              <i
                                className="fa-solid fa-list"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Report"
                              ></i>
                            </Link> */}
                            <Link to="/issuebooks">
                              {/* <img
                                src="assets/media/svg/icons/address-book-solid.svg"
                                width="30"
                                height="30"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Issue Books"
                              /> */}

                              <img
                                width="30"
                                height="30"
                                src="https://img.icons8.com/ios-filled/50/address-book.png"
                                alt="address-book"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Issue Books"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body pt-6 pb-0">
                          {issuebooksData.map((item, index) => (
                            <div key={item.BookLabelId}>
                              <div className="d-flex flex-stack">
                                <div className="symbol symbol-40px me-4">
                                  <div
                                    className="symbol-label fs-2 fw-semibold  text-inverse-success"
                                    style={{
                                      backgroundColor:
                                        colors[index % colors.length],
                                    }}
                                  >
                                    {item.BookName[0].toUpperCase()}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                  <div className="flex-grow-1 me-2">
                                    <a
                                      // href="pages/user-profile/overview.html"
                                      className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                    >
                                      {item.BookName}
                                    </a>
                                    <span className="text-muted fw-semibold d-block fs-7">
                                      {item.Author}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 mb-xl-10 mt-xl-10">
                      <div
                        id="kt_sliders_widget_2_slider"
                        className="card carousel carousel-custom carousel-stretch slide h-xl-100"
                        data-bs-ride="carousel"
                        data-bs-interval="5000"
                      >
                        <div className="card-header pt-5">
                          <a to="/duebooks">
                            <h4 className="card-title d-flex align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Return Books
                              </span>
                              <span className="text-gray-500 mt-1 fw-bold fs-7">
                                <span>
                                  {count.map(
                                    (item) =>
                                      item.RecType === "ReturnsCount" &&
                                      item.Count
                                  )}
                                </span>
                                &nbsp;books Due
                              </span>
                            </h4>
                          </a>
                          <div
                            className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            {/* <Link to="/duebooksreport">
                              <i className="fa-solid fa-list"></i>
                            </Link> */}
                            <Link to="/duebooks">
                              {/* <img
                                src="assets/media/svg/icons/return.svg"
                                className="w-20px"
                                style={{ fontSize: 20 }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Return Books"
                              /> */}
                              <img
                                width="30"
                                height="30"
                                src="https://img.icons8.com/ios-filled/50/return-book.png"
                                alt="return-book"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body pt-6">
                          {dueBooksData.map((item, index) => (
                            <div key={index}>
                              <div className="d-flex flex-stack">
                                <div className="symbol symbol-40px me-4">
                                  <div
                                    className="symbol-label fs-2 fw-semibold  text-inverse-success"
                                    style={{
                                      backgroundColor:
                                        colors[index % colors.length],
                                    }}
                                  >
                                    {item.Book[0]}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                  <div className="flex-grow-1 me-2">
                                    <a
                                      // href="pages/user-profile/overview.html"
                                      className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                    >
                                      {item.Book}
                                    </a>
                                    <span className="text-muted fw-semibold d-block fs-7">
                                      {item.Author}
                                    </span>
                                  </div>
                                  {/* <a
                                href="#"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                              >
                                <i className="ki-duotone ki-arrow-right fs-2">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </a> */}
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
