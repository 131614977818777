import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { API_BASE_LB_URL } from "../Config";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLabelContext } from "../context";

const ReturnBook = (props) => {
  const [finesData, setFinesData] = useState([]);
  const [comment, setComment] = useState("");
  const [orgId, setOrgId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);
  const { returnBooks } = useLabelContext();
  const [rowData, setRowdata] = useState([]);
  const [returnDetails, setReturnDetails] = useState({
    comment: "",
    return_date: "",
  });
  const navigate = useNavigate();

  const handlecancel = () => {
    setReturnDetails({
      comment: "",
      return_date: "",
    });
  };

  const handleSubmit = async () => {
    console.log(returnDetails);
    const requestData = {
      OrgId: orgId,
      Id: rowData.RentalId,
      ReturnDate: returnDetails.return_date,
      ReturnComment: "" || returnDetails.comment,
      Fine: rowData.CAluclatedFine,
      UpdatedBy: updatedId,
    };
    console.log(requestData);
    try {
      // Make a POST request to update data
      const response = await fetch(`${API_BASE_LB_URL}duebook/ReturnBook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const responseBody = await response.json();
      console.log(responseBody);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/duebooks");
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
          setUpdatedId(UpdatedById);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     // Make a POST request to update data
    //     const response = await fetch(
    //       `${API_BASE_LB_URL}duebook/GetIssuedBooksWithFine?OrgId=${orgId}&regId=${returnId}`
    //     );
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     const result = await response.json();
    //     // sessionStorage.setItem("rowdata", JSON.stringify(result));
    //     // Uncomment the following line if you want to use `result` with `setRowdata`
    //     // setRowdata(result);
    //     setFinesData(result);
    //   } catch (error) {
    //     console.error("Error updating data:", error);
    //   }
    // };

    if (returnBooks) {
      console.log(returnBooks);
      sessionStorage.setItem("returnBooks", JSON.stringify(returnBooks));
    }
    const storedreturnbooks = sessionStorage.getItem("returnBooks");
    if (storedreturnbooks) {
      setFinesData(JSON.parse(storedreturnbooks));
    }
    // fetchData();
  }, [returnBooks, navigate, orgId, updatedId]);

  //FORMATTING DATE IN EDIT STUDENT DETAILS
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    // Ensure the date is valid
    if (isNaN(date.getTime())) return "";

    // Format to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const handleReturn = async (item) => {
    console.log(item);
    setRowdata(item);
  };

  const handleComment = (e) => {
    e.preventDefault();
    setComment(e.target.value);
  };

  return (
    <>
      <Header />
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Return Books
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6"></div>

                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                            <th className="text-start min-w-70px px-5">
                              Actions
                            </th>

                            <th className="min-w-125px">ISSUED TO</th>

                            <th className="min-w-125px">Label</th>
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-125px">Issue Date</th>
                            <th className="min-w-125px">Due Date</th>
                            <th className="min-w-125px">Fine</th>
                            <th className="min-w-125px">Comment</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {finesData.map((item, index) => (
                            <tr key={index}>
                              <td className="text-start">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-flex btn-center btn-active-light-primary"
                                  style={{
                                    backgroundColor: "#8096e0",
                                  }}
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasedit"
                                  aria-controls="offcanvasExample"
                                  onClick={() => handleReturn(item)}
                                >
                                  Return
                                </button>
                              </td>
                              <td>{item.FullName}</td>
                              <td>{item.labelName}</td>
                              <td>{item.BookName}</td>
                              <td>{formatDate(item.IssueDate)}</td>
                              <td>{formatDate(item.DueDate)}</td>
                              <td>
                                {item.CAluclatedFine > 0
                                  ? item.CAluclatedFine
                                  : ""}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={item.IssueComment}
                                  onChange={handleComment}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasedit"
        aria-labelledby="offcanvasExampleLabel"
        style={{ width: "500px" }}
      >
        <div className="offcanvas-header bg-secondary">
          <h5 className="offcanvas-title " id="offcanvasExampleLabel">
            Return Book
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <form className="row p-7">
          <div className="col-12 col-md-6 mb-5 fv-row">
            <label htmlFor="firstname" className="required form-label mb-3">
              Comment
            </label>
            <input
              required
              type="text"
              className="form-control form-control-sm form-control-solid"
              // name="campaign_name"
              placeholder=""
              id="firstname"
              name="comment"
              value={returnDetails.comment || ""}
              onChange={(e) => {
                setReturnDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </div>

          <div className="col-6 d-flex flex-column dropdown ">
            <label htmlFor="date" className=" form-label mb-3">
              Return Date
            </label>
            <input
              type="date"
              style={{ width: 160 }}
              className="form-control form-control-sm form-control-solid"
              placeholder=""
              id="date"
              name="return_date"
              value={returnDetails.return_date || ""}
              onChange={(e) => {
                setReturnDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </div>

          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              data-bs-dismiss="offcanvas"
              className="btn btn-danger ms-2"
              onClick={handlecancel}
            >
              Cancel
            </button>
            {/* <p>{message}</p> */}
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ReturnBook;
