import React, { useState, useEffect } from "react";
import { Select } from "antd";
import Pagination from "./Pagination";
import Header from "./Header";
import Footer from "./Footer";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { API_BASE_LB_URL } from "../Config";
import { useNavigate } from "react-router-dom";

import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import { Link } from "react-router-dom";

const DueBooksReports = (props) => {
  const [getData, setGetData] = useState([]);
  //   const [progid, setProgId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [errMsg, setErrMsg] = useState("Loading...");
  const [details, setDetails] = useState({
    student_name: "",
    book_name: "",
  });

  //pagination

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}reports/duebookreport?OrgId=${orgId}&SearchText='${details.student_name}'&BookName='${details.book_name}'`
      );
      console.log(response);
      if (!response.ok) {
        setGetData([]);
      }
      const result = await response.json();
      if (result.length === 0) {
        setErrMsg("No Records Found");
      }
      setGetData(result);
      setCurrentPage(1);
    } catch (error) {
      setErrMsg("No Records Found");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    // Ensure the date is valid
    if (isNaN(date.getTime())) return "";

    // Format to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const handlePdfDownload = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    const tableData = getData.map((item, index) => [
      index + 1,
      item.FullName,
      item.HallTicketNo,
      item.Book,
      formatDate(item.IssueDate),
      formatDate(item.DueDate),
    ]);

    // Add headers
    const headers = [
      "Sl.No",
      "Name",
      "HallTicket/EmpId",
      "Book Name",
      "Issued Date",
      "Due Date",
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      columnStyles: {
        1: { cellWidth: "auto", halign: "center" },
        2: { cellWidth: "auto", halign: "center" },
        3: { cellWidth: "auto", halign: "center" },
        4: { cellWidth: "auto", halign: "center" },
        5: { cellWidth: "auto", halign: "center" },
        6: { cellWidth: "auto", halign: "center" },
      },
      headStyles: {
        fillColor: [224, 224, 224], // Header background color Grey background color
        textColor: [0, 0, 0], // Black text color
        halign: "center",
      },
    });

    doc.save("duebooksreport.pdf");
    // const opt = {
    //   margin: 0.3,
    //   filename: "myfile.pdf",
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: { scale: 2.0 },
    //   jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    // };

    // html2pdf().set(opt).from(element).save();
  };

  const handleExcelDownload = () => {
    const filteredData = getData.map((record, index) => ({
      "Sl.No": index + 1,
      Name: record.FullName,
      " HallTicket/EmpId": record.HallTicketNo,
      "Book Name": record.Book,
      "Issued Date": formatDate(record.IssueDate),
      "Due Date": formatDate(record.DueDate),
    }));

    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllRecords");

    // Write the workbook to a file
    XLSX.writeFile(wb, "duebooksreport.xlsx");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setLoading(true);
    const getDataFromDB = async () => {
      try {
        const response = await fetch(
          `${API_BASE_LB_URL}reports/duebookreport?OrgId=${orgId}&SearchText=NULL&BookName=NULL`
        );
        console.log(response);
        if (response.ok) {
          const result = await response.json();
          console.log(result);

          setGetData(result);
        }
      } catch (error) {
        // setErrMsg("Try");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    // Call the async function
    getDataFromDB();
  }, [orgId]);
  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-2" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Books Due Report
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6 ">
                    <div className="card-title row w-800px">
                      <div className="col-6 col-lg-4 d-flex flex-column align-items-start my-1 mx-1 p-0">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="name"
                        >
                          Name/HallTicket/EmpID
                        </label>
                        <div className="d-flex align-items-center position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            id="name"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-280px ps-13"
                            placeholder="Search by name, hall ticket,employee ID "
                            name="student_name"
                            value={details.student_name || ""}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-startmy-1 mx-1 p-0">
                        {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="mobile"
                        >
                          Book Name
                        </label>
                        <div className="d-flex align-items-center position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            id="mobile"
                            placeholder="Search with Book"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-lg-200px w-180px ps-13"
                            name="book_name"
                            value={details.book_name || ""}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                          onClick={handleSearch}
                        >
                          Search
                          {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3 px-4"
                          onClick={handlePdfDownload}
                          data-bs-target="#kt_customers_export_modal"
                        >
                          <i className="fa-solid fa-file-pdf fs-2"></i>
                        </button>
                      </div>

                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          onClick={handleExcelDownload}
                          data-bs-target="#kt_customers_export_modal"
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  {getData.length > 0 ? (
                    <div className="card-body pt-0">
                      <div className="dt-container dt-bootstrap5 dt-empty-footer">
                        <div className="table-responsive">
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-5"
                            id="kt_customers_table"
                          >
                            <thead>
                              <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                                <th className="text-start min-w-125px px-5">
                                  Sl.No
                                </th>
                                <th className="min-w-125px dt-orderable-asc dt-orderable-desc">
                                  Name
                                </th>
                                <th className="min-w-125px">
                                  HallTicket/EmpID
                                </th>
                                {/* <th className="min-w-125px">Status</th> */}
                                <th className="min-w-125px">Book Name</th>
                                <th className="min-w-125px">Issued Date</th>
                                <th className="min-w-125px">Due Date </th>
                              </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                              {currentItems.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-start px-8">
                                    {index + 1}
                                  </td>
                                  <td>
                                    <a className="text-gray-800 text-hover-primary mb-1">
                                      {item.FullName}
                                    </a>
                                  </td>
                                  <td>
                                    <a className="text-gray-600 text-hover-primary mb-1">
                                      {item.HallTicketNo}
                                    </a>
                                  </td>
                                  {/* <td>
                              <div className="badge badge-light-danger">
                                Locked
                              </div>
                            </td> */}
                                  <td>{item.Book}</td>
                                  <td>{formatDate(item.IssueDate)} </td>
                                  <td>{formatDate(item.DueDate)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={getData.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  ) : (
                    <p className="text-center">
                      {errMsg ? errMsg : "No Records Found"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DueBooksReports;
