import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  // const [userDetails, setUserDetails] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    console.log("Raw userDetails from sessionStorage:", userDetailsString);

    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        console.log("Parsed userDetails:", parsedUserDetails);

        // If parsedUserDetails is an array, access the first item
        const userDetails = Array.isArray(parsedUserDetails)
          ? parsedUserDetails[0]
          : parsedUserDetails;
        const orgId = userDetails.OrgId;

        if (orgId) {
          setUserDetails(userDetails);
          setOrgId(orgId);

          const constructedLogoUrl = `https://library.ibizaccounts.in/images/clients/${orgId}_logo.png`;
          setLogoUrl(constructedLogoUrl);
          console.log("Constructed Logo URL:", constructedLogoUrl);
        } else {
          console.error("OrgId is undefined or null in userDetails");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
      }
    } else {
      console.error("No userDetails found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        setUserDetails(parsedUserDetails);
        // console.log("Retrieved userDetails:", parsedUserDetails);
      } catch (error) {
        console.error("Error parsing userDetails:", error);
      }
    } else {
      console.error("No userDetails found in sessionStorage");
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("userDetails");
    navigate("/");
  };

  return (
    <div
      id="kt_header"
      className="header fixed-top "
      style={{
        height: 100,
        top: 0,
        zindex: -1,
        backgroundImage: "linear-gradient(to right, white, #24c55b)",
      }}
      data-kt-sticky="true"
      data-kt-sticky-name="header"
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
    >
      <div className="container-xxl d-flex flex-grow-0 flex-stack">
        <div>
          <Link to="/">
            <img
              src={logoUrl}
              className=" h-25px h-lg-50px me-2 me-lg-9 "
              alt="logo"
            />
          </Link>
        </div>
        {/* <div>
          <img
            src="assets/media/collegelogo/library_logo.webp"
            className=" h-25px h-lg-50px me-2 me-lg-9 w-250px d-none d-md-block"
            alt="logo"
          />
        </div> */}

        <div
          className="topbar d-flex align-items-stretch flex-shrink-0"
          id="kt_topbar"
        >
          <div
            className="cursor-pointer symbol symbol-30px symbol-lg-40px"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            title="Dashboard"
          >
            <div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary ">
              <Link to="/">
                <i className="fa-solid fa-house text-white"></i>
              </Link>
            </div>
          </div>

          <div className="d-flex align-items-center ms-2 mx-2 ms-lg-4">
            <button
              className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-center"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-placement="top"
              title=""
            >
              <i className="fa-solid fa-book-atlas text-white "></i>
            </button>
            <div
              className="menu menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold "
              aria-labelledby="dropdownMenuButton"
            >
              <ul className="dropdown-menu">
                <div className="menu-item px-3">
                  <Link to="/booktitles" className="menu-link px-3">
                    Book Titles
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/booklabels" className="menu-link px-3">
                    Book Labels
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/racks" className="menu-link px-3">
                    Racks
                  </Link>
                </div>
              </ul>
            </div>
          </div>
          <div className="d-flex align-items-center ms-2 mx-2 ms-lg-4">
            <button
              className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-placement="top"
              title="Reports"
            >
              <i className="ki-duotone ki-category fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </button>
            <div
              className="menu menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold "
              aria-labelledby="dropdownMenuButton"
            >
              <ul className="dropdown-menu">
                <div className="menu-item px-3">
                  <Link to="/studentsreports" className="menu-link px-3">
                    Students
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/employeesreports" className="menu-link px-3">
                    Employees
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/booksreport" className="menu-link px-3">
                    Books
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/issuebooksreport" className="menu-link px-3">
                    Issue Books
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/duebooksreport" className="menu-link px-3">
                    Due Books
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/returnbooksreport" className="menu-link px-3">
                    Return Books
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/dailyreport" className="menu-link px-3">
                    Daily Report
                  </Link>
                </div>
                <div className="menu-item px-3">
                  <Link to="/persontransaction" className="menu-link px-3">
                    History
                  </Link>
                </div>
              </ul>
            </div>
          </div>
          <div
            className="d-flex align-items-center ms-2 ms-lg-4"
            id="kt_header_user_menu_toggle"
          >
            <div
              className="cursor-pointer symbol symbol-30px symbol-lg-40px"
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              title="Barcode"
            >
              <div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary ">
                <Link to="/barcode">
                  <i className="fa-solid fa-barcode text-white"></i>
                </Link>
              </div>
            </div>
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                  <div className="symbol symbol-50px me-5">
                    <img alt="Logo" src="assets/media/avatars/300-3.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="fw-bold d-flex align-items-center fs-5">
                      Max Smith
                      <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                        Pro
                      </span>
                    </div>
                    <a className="fw-semibold text-muted text-hover-primary fs-7">
                      max@kt.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="separator my-2"></div>
              <div className="menu-item px-5">
                <a href="account/overview.html" className="menu-link px-5">
                  My Profile
                </a>
              </div>
              <div className="menu-item px-5">
                <a href="apps/projects/list.html" className="menu-link px-5">
                  <span className="menu-text">My Projects</span>
                  <span className="menu-badge">
                    <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                      3
                    </span>
                  </span>
                </a>
              </div>
              <div
                className="menu-item px-5"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="left-start"
                data-kt-menu-offset="-15px, 0"
              >
                <a className="menu-link px-5">
                  <span className="menu-title">My Subscription</span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                  <div className="menu-item px-3">
                    <a href="account/referrals.html" className="menu-link px-5">
                      Referrals
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="account/billing.html" className="menu-link px-5">
                      Billing
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/statements.html"
                      className="menu-link px-5"
                    >
                      Payments
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/statements.html"
                      className="menu-link d-flex flex-stack px-5"
                    >
                      Statements
                      <span
                        className="ms-2 lh-0"
                        data-bs-toggle="tooltip"
                        title="View your statements"
                      >
                        <i className="ki-duotone ki-information-5 fs-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </span>
                    </a>
                  </div>
                  <div className="separator my-2"></div>
                  <div className="menu-item px-3">
                    <div className="menu-content px-3">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input w-30px h-20px"
                          type="checkbox"
                          value="1"
                          // checked="checked"
                          defaultChecked
                          name="notifications"
                        />
                        <span className="form-check-label text-muted fs-7">
                          Notifications
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-item px-5">
                <a href="account/statements.html" className="menu-link px-5">
                  My Statements
                </a>
              </div>
              <div className="separator my-2"></div>
              <div
                className="menu-item px-5"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="left-start"
                data-kt-menu-offset="-15px, 0"
              >
                <a className="menu-link px-5">
                  <span className="menu-title position-relative">
                    Mode
                    <span className="ms-5 position-absolute translate-middle-y top-50 end-0">
                      <i className="ki-duotone ki-night-day theme-light-show fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                        <span className="path7"></span>
                        <span className="path8"></span>
                        <span className="path9"></span>
                        <span className="path10"></span>
                      </i>
                      <i className="ki-duotone ki-moon theme-dark-show fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                  </span>
                </a>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                  data-kt-menu="true"
                  data-kt-element="theme-mode-menu"
                >
                  <div className="menu-item px-3 my-0">
                    <a
                      className="menu-link px-3 py-2"
                      data-kt-element="mode"
                      data-kt-value="light"
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <i className="ki-duotone ki-night-day fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                          <span className="path5"></span>
                          <span className="path6"></span>
                          <span className="path7"></span>
                          <span className="path8"></span>
                          <span className="path9"></span>
                          <span className="path10"></span>
                        </i>
                      </span>
                      <span className="menu-title">Light</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center ms-2 ms-lg-4">
            <a
              className="btn btn-flex flex-center  align-self-center px-0 px-md-3 h-30px w-30px w-md-auto h-lg-40px ms-2 ms-lg-4"
              style={{ backgroundColor: "#ede6d3" }}
              // data-bs-toggle="modal"
              // data-bs-target="#kt_modal_invite_friends"
              onClick={handleLogout}
            >
              <i className="fa-solid fa-right-from-bracket"></i>
              <span className="d-none d-md-inline ms-2">Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
