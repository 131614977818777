import React from "react";

const Footer = (props) => {
  return (
    <div className="footer pb-1 d-flex flex-lg-column pt-0" id="kt_footer">
      <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-gray-900 order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">2024&copy;</span>
          <a
            href="https://perennialcode.in/"
            target="_blank"
            className="text-gray-800 text-hover-primary"
          >
            Perennial Code
          </a>
        </div>

        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item">
            <a
              href="https://perennialcode.in/"
              target="_blank"
              className="menu-link px-2"
            >
              About
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://perennialcode.in/"
              target="_blank"
              className="menu-link px-2"
            >
              Support
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://perennialcode.in/"
              target="_blank"
              className="menu-link px-2"
            >
              Purchase
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
