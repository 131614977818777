import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_LB_URL } from "../Config";
import { useLabelContext } from "../context";

const BookLabels = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [errMsg, setErrMsg] = useState("Loading...");
  const [booklabelsData, setBooklabelsData] = useState([]);
  const [finalOrgId, setFinalOrgId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [getData, setGetData] = useState([]);
  const [load, setLoad] = useState(true);
  const { setLabelDataa } = useLabelContext();

  const navigate = useNavigate();

  const handleBookLabelsSearch = async () => {
    if (!finalOrgId) {
      console.error("Organization ID is not set.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}bookmaster/getallbookmaster?OrgId=${finalOrgId}&SearchText='${searchInput}'`
      );
      if (!response.ok) {
        setErrMsg("No Records Found");
        setBooklabelsData([]);
      }
      const result = await response.json();
      const limitedResult = result.slice(0, 15);
      setBooklabelsData(limitedResult);
    } catch (error) {
      setErrMsg("No Records Found");
    } finally {
      setLoading(false);
    }
  };

  const getGenerateLabelFromDB = async (item) => {
    // const bookData = JSON.parse(sessionStorage.getItem("bookData"));

    // const selectedBook = bookData.find(
    //   (book) => book.BookInventoryId === selectedBookId
    // );
    // if (!selectedBook) {
    //   console.error("Book not found with the given ID.");
    //   return;
    // }

    const bookInventoryId = item.BookInventoryId;
    if (!bookInventoryId) {
      console.error("BookInventoryId not found for the selected book.");
      return;
    }
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}booklabel/getLabelsByInventoryId?InventoryId=${bookInventoryId}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.length === 1) {
        const newItems = Array.from(
          { length: result[0].Quantity },
          (_, index) => ({
            ...result[0],
            IsActive: true,
          })
        );
        console.log("data passed", newItems);
        setLabelDataa(newItems);
      } else {
        setLabelDataa(result);
      }

      console.log(result);
    } catch (error) {
      console.log("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          setUserDetails(parsedUserDetails);
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          setFinalOrgId(orgId);
          setLoad(false);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (finalOrgId) {
      setLoading(true);
      const getBooksFromDB = async () => {
        if (!finalOrgId) {
          console.error("OrgId is undefined in getBooksFromDB");
          return;
        }
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookmaster/getallbookmaster?OrgId=${finalOrgId}&SearchText=NULL`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();
          const limitedResult = result.slice(0, 20);
          sessionStorage.setItem("bookData", JSON.stringify(result));
          setBooklabelsData(limitedResult);
        } catch (error) {
          console.error("Fetch error:", error);
        } finally {
          setLoading(false);
        }
      };

      getBooksFromDB();
    }
  }, [finalOrgId]);
  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-3" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Book Labels
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          data-kt-customer-table-filter="search"
                          className="form-control form-control-solid w-220px ps-13"
                          placeholder=" Name or Author "
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-2 w-60px"
                          onClick={handleBookLabelsSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  {booklabelsData.length > 0 ? (
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_customers_table"
                        >
                          <thead>
                            <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                              <th className=" min-w-120px px-5">Actions</th>
                              <th className="min-w-105px">Name</th>
                              <th className="min-w-125px">Author</th>
                              <th className="min-w-125px">Publisher</th>
                              <th className="min-w-125px">Edition</th>
                              <th className="min-w-125px">Price</th>
                              <th className="min-w-125px">Quantity</th>
                              <th className="min-w-125px">IsLabeled</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {booklabelsData.map((item) => (
                              <tr key={item.BookInventoryId}>
                                <td className="text-start">
                                  <Link
                                    to={`/generatelabels`}
                                    className="dropdown-item"
                                  >
                                    <button
                                      type="button"
                                      id="dropdownMenuButton"
                                      className="btn btn-primary btn-flex btn-center btn-active-light-primary"
                                      style={{
                                        backgroundColor: "#8096e0",
                                      }}
                                      onClick={() =>
                                        getGenerateLabelFromDB(item)
                                      }
                                      // onClick={() =>
                                      //   getByLabel(item)
                                      // }
                                    >
                                      Generate label
                                    </button>
                                  </Link>
                                </td>
                                <td>
                                  <a className="text-gray-800 text-hover-primary mb-1">
                                    {item.Name}
                                  </a>
                                </td>
                                <td>{item.Author}</td>
                                <td>{item.Publisher}</td>
                                <td>{item.Edition}</td>
                                <td>{item.Price}</td>
                                <td>{item.Quantity}</td>

                                <td>
                                  <div className="border border-danger rounded w-40px text-danger text-center">
                                    {item.IsLabled === true ? "Yes" : "No"}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center">
                      {errMsg ? errMsg : "No Records Found"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookLabels;
