import React, { useState, useEffect } from "react";
import Header from "./Header";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { API_BASE_LB_URL } from "../Config";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);

  // Ensure the date is valid
  if (isNaN(date.getTime())) return "";

  // Format to YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
};

const IssueBooksReport = (props) => {
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const navigate = useNavigate();
  const [errMsg, setErrMsd] = useState("");

  const [details, setDetails] = useState({
    from_date: "",
    to_date: "",
    searchInput: "",
  });

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePdfDownload = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    const tableData = getData.map((item, index) => [
      index + 1,
      item.IssueDate,
      item.FullName,
      item.HallTicketNo,
      item.BookName,
      item.Label,
      item.Author,
      item.DueDate,
      item.IssueComment,
    ]);
    const headers = [
      "Sl.No",
      "IssueDate",
      "FullName",
      "Hall Ticket/Admission",
      "BookName",
      "Label",
      "Author",
      "DueDate",
      "IssueComment",
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      columnStyles: {
        0: { cellWidth: "auto", halign: "center" },
        1: { cellWidth: "auto", halign: "center" },
        2: { cellWidth: "auto", halign: "center" },
        3: { cellWidth: "auto", halign: "center" },
        4: { cellWidth: "auto", halign: "center" },
        5: { cellWidth: "auto", halign: "center" },
        6: { cellWidth: "auto", halign: "center" },
        7: { cellWidth: "auto", halign: "center" },
      },
      headStyles: {
        fillColor: [224, 224, 224], // Header background color Grey background color
        textColor: [0, 0, 0], // Black text color
        halign: "center",
      },
    });

    doc.save("issuebooksreport.pdf");
    // const opt = {
    //   margin: 0.3,
    //   filename: "myfile.pdf",
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: { scale: 2.0 },
    //   jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    // };

    // html2pdf().set(opt).from(element).save();
  };
  const handleExcelDownload = () => {
    const filteredData = getData.map((record) => ({
      IssueDate: record.IssueDate,
      FullName: record.FullName,
      " Hall Ticket/Admission": record.HallTicketNo,
      BookName: record.BookName,
      Label: record.Label,
      Author: record.Author,
      DueDate: record.DueDate,
      IssueComment: record.IssueComment,
    }));

    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllRecords");

    // Write the workbook to a file
    XLSX.writeFile(wb, "issuebooksreport.xlsx");
  };
  const handleSearch = async () => {
    // console.log(details);
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}Reports/BookIssueReport?OrgId=${orgId}&FromDate=${
          details.from_date
        }&ToDate=${details.to_date ? details.to_date : new Date()}&BookName=${
          details.searchInput
        }`
      );
      console.log(response);
      if (!response.ok) {
        setErrMsd("Network response was not ok");
        setGetData([]);
      }

      const result = await response.json();
      setGetData(result);
      setCurrentPage(1);
    } catch (error) {
      setErrMsd("No Records Found");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    //FOR GETTING STUDENT DATA FROM DATABASE
    // setLoading(true);
    // const getDataFromDB = async () => {
    //   try {
    //     const response = await fetch(
    //       `${API_BASE_LB_URL}Reports/BookIssueReport?OrgId=${orgId}&FromDate=&ToDate=&BookName=`
    //     );
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     const result = await response.json();
    //     setGetData(result);
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // Call the async function
    // getDataFromDB();
  }, [navigate, orgId]);

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Books Issued Report
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row">
                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                        <label
                          className="required fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="fromdate"
                        >
                          From Date:
                        </label>
                        <input
                          type="date"
                          id="fromdate"
                          className="form-control form-control-solid w-200px ps-3"
                          name="from_date"
                          value={details.from_date}
                          onChange={(e) => {
                            setDetails((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                        />
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                        <label
                          className="required fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="todate"
                        >
                          To Date:
                        </label>
                        <input
                          type="date"
                          id="todate"
                          className="form-control form-control-solid w-200px ps-3"
                          name="to_date"
                          value={details.to_date}
                          onChange={(e) => {
                            setDetails((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                        />
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="bookname"
                        >
                          Book Name:
                        </label>
                        <div className="d-flex align-items-center position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            id="bookname"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-180px ps-13"
                            placeholder="Search with Name "
                            name="searchInput"
                            onChange={(e) => {
                              setDetails((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                          onClick={handleSearch}
                        >
                          Search
                          {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3 px-4"
                          onClick={handlePdfDownload}
                        >
                          <i className="fa-solid fa-file-pdf fs-2"></i>
                        </button>
                      </div>
                      <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          onClick={handleExcelDownload}
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Export
                        </button>
                      </div>
                      <div
                        className="d-flex justify-content-end align-items-center d-none"
                        data-kt-customer-table-toolbar="selected"
                      >
                        <div className="fw-bold me-5">
                          <span
                            className="me-2"
                            data-kt-customer-table-select="selected_count"
                          ></span>
                          Selected
                        </div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-kt-customer-table-select="delete_selected"
                        >
                          Delete Selected
                        </button>
                      </div>
                    </div>
                  </div>

                  {currentItems.length > 0 ? (
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_customers_table"
                        >
                          <thead>
                            <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                              <th className="text-center min-w-125px">Sl.No</th>
                              <th className="min-w-125px"> Issue Date</th>
                              <th className="min-w-125px">Issued To</th>
                              <th className="min-w-125px">
                                HallTicket/Admission
                              </th>
                              <th className="min-w-125px">Book Name</th>
                              <th className="min-w-125px">Label</th>
                              <th className="min-w-125px">Author</th>
                              <th className="min-w-125px">Due Date</th>
                              <th className="min-w-125px">Issue Comment</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {currentItems.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td>{formatDate(item.IssueDate)}</td>
                                <td>{item.FullName}</td>
                                <td>{item.HallTicketNo}</td>
                                <td>{item.BookName}</td>
                                <td>{item.Label}</td>
                                <td>{item.Author}</td>
                                <td>{formatDate(item.DueDate)}</td>
                                <td>{item.IssueComment}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={getData.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  ) : (
                    <p className="text-center">{errMsg}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IssueBooksReport;
