import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { API_BASE_LB_URL } from "../Config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "./Header";

const Racks = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [RacksRowdata, setRacksRowdata] = useState([]);
  const [getRacksData, setGetRacksData] = useState([]);
  const [successMsg, setSuccessMsg] = useState(false);
  const [getData, setGetData] = useState([]);
  const [rackName, setRackName] = useState("");
  const [errmsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState(null);
  const [updatedId, setUpdatedId] = useState(null);

  const [searchBook, setSearchBook] = useState({
    search_book: "",
    category: 0,
    book_type: 0,
  });
  const [course, setCourse] = useState([]);
  const [bookType, setBookType] = useState([]);
  const [details, setDetails] = useState({
    row_no: "",
    rack_name: "",
  });

  const courseOptions = course.map((course) => ({
    value: course.Id,
    label: course.Name,
  }));

  const typeOptions = bookType?.map((type) => ({
    value: type.Id,
    label: type.Name,
  }));

  const handleAddRack = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to update data
      const response = await fetch(`${API_BASE_LB_URL}bookrack/RackAdd`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          OrgId: orgId,
          Name: details.rack_name,
          Row: details.row_no,
          CreatedBy: updatedId,
        }),
      });
      console.log(response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        });
      }

      // Clear the form after successful update
      setDetails({
        row_no: "",
        rack_name: "",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setDetails({
      row_no: "",
      rack_name: "",
    });
  };

  const handleEditRacK = (item) => {
    setRacksRowdata(item);
    console.log(item);
  };

  const handleEditRackItem = async (e) => {
    e.preventDefault();
    const requestData = {
      Id: RacksRowdata.Id,
      OrgId: orgId,
      Name: RacksRowdata.Name,
      Row: RacksRowdata.Row,
      UpdatedBy: updatedId,
      IsActive: 1,
    };
    console.log("Request Body:", JSON.stringify(requestData, null, 2));
    try {
      // Make a POST request to update data
      const response = await fetch(`${API_BASE_LB_URL}bookrack/EditRack`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "success.",
          confirmButtonText: "OK",
        });
      }

      console.log("Update successful");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Retry",
      });
    }
  };

  const handleAssignRacK = (item) => {
    setRacksRowdata(item);
    setIsShow(true);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (searchBook.category !== 0) {
      if (searchBook.search_book !== "") {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookrack/getbooksByRacKId?BookCatId=${searchBook.category}&BookTypeId=${searchBook.book_type}&SearchText='${searchBook.search_book}'&BookRackId=${RacksRowdata.Id}&OrgId=${orgId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();

          setGetData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else {
        alert("Choose Book Name");
      }
    } else {
      alert("Choose Category");
    }
  };

  const handleSearchRack = async () => {
    try {
      const response = await fetch(
        `${API_BASE_LB_URL}bookrack/getbookRacks?OrgId=${orgId}&SearchText='${rackName}'`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setGetRacksData(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (e, item) => {
    console.log(item);
    console.log(e.target.checked);
    if (e.target.checked) {
      // console.log("Checked :", e.target.checked, item);
      const requestData = {
        BookrackId: RacksRowdata.Id,
        UpdatedBy: updatedId,
        Id: item.LabelId,
      };
      // console.log(requestData);
      try {
        // Make a POST request to update data
        const response = await fetch(
          `${API_BASE_LB_URL}bookrack/AssignBookToRack`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );
        const responseBody = await response.json();
        setSuccessMsg("Book Assigned to Rack");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "success.",
            confirmButtonText: "OK",
          });
        }

        // console.log("Update successful");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    }

    if (!e.target.checked) {
      console.log("Checked :", e.target.checked, item);
      const requestData = {
        Id: item.LabelId,
      };
      console.log(requestData);
      try {
        // Make a POST request to update data
        const response = await fetch(
          `${API_BASE_LB_URL}bookrack/UnAssignBookToRack`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );
        const responseBody = await response.json();
        console.log(responseBody);
        setSuccessMsg("Book Unassigned from Rack");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "success.",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "Retry",
        });
      }
    }
  };

  useEffect(() => {
    const userDetailsString = sessionStorage.getItem("userDetails");
    if (userDetailsString) {
      try {
        const parsedUserDetails = JSON.parse(userDetailsString);
        if (Array.isArray(parsedUserDetails) && parsedUserDetails.length > 0) {
          const orgId = parsedUserDetails[0]?.OrgId;
          const UpdatedById = parsedUserDetails[0]?.RoleId;
          // console.log("OrgId in userDetails:", orgId);
          setOrgId(orgId);
          setUpdatedId(UpdatedById);
        } else {
          console.error("Invalid userDetails format or empty array.");
          navigate("/");
        }
      } catch (error) {
        console.error("Error parsing userDetails:", error);
        navigate("/");
      }
    } else {
      console.error("No userDetails found in sessionStorage");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (orgId) {
      const getRacksDataFromDB = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookrack/getbookRacks?OrgId=${orgId}&SearchText=NULL`
          );
          if (!response.ok) {
            setErrMsg("Loading...");
          }
          const result = await response.json();
          setGetRacksData(result);
        } catch (error) {
          setErrMsg("No Records Found");
        }
      };

      const getCourse = async () => {
        try {
          const response = await fetch(
            `${API_BASE_LB_URL}bookmaster/getbookcategories?OrgId=${orgId}`
          );
          if (!response.ok) {
            setErrMsg("Loading...");
          }
          const result = await response.json();

          setCourse(result);
        } catch (error) {
          setErrMsg("No Records Found");
        }
      };

      const getBookType = async () => {
        try {
          const response = await fetch(`
            ${API_BASE_LB_URL}bookmaster/GetBookTypes?OrgId=${orgId}`);
          if (!response.ok) {
            setErrMsg("Loading...");
          }
          const result = await response.json();
          setBookType(result.ResultData);
        } catch (error) {
          setErrMsg("No Records Found");
        }
      };
      getRacksDataFromDB();
      getCourse();
      getBookType();
    }
  }, [navigate, orgId, updatedId]);

  return (
    <>
      <Header />
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Book Rack
                  </h1>
                </div>
                {/* <a
                  href="#"
                  className="btn bg-body d-flex flex-center h-35px h-lg-40px"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_campaign"
                >
                  Create
                  <span className="d-none d-sm-inline ps-2">New</span>
                </a> */}
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card row d-flex flex-row">
                  {/* Racks Container  */}
                  <div>
                    <div className="card-header border-0 pt-6 ">
                      <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1 ">
                          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <input
                            type="text"
                            data-kt-customer-table-filter="search"
                            className="form-control form-control-solid w-160px ps-10"
                            placeholder="Search with Rack"
                            value={rackName}
                            onChange={(e) => setRackName(e.target.value)}
                          />
                          <button
                            type="button"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-40px mx-3"
                            onClick={handleSearchRack}
                          >
                            Search
                            {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasAddRack"
                            aria-controls="offcanvasExample"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <div className="card-toolbar">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-customer-table-toolbar="base"
                        >
                          {/* OFFCANVAS FOR ADD RACK  */}
                          <div
                            className="offcanvas offcanvas-end"
                            tabIndex="-1"
                            id="offcanvasAddRack"
                            aria-labelledby="offcanvasAddRackLabel"
                            style={{ width: "500px" }}
                          >
                            <div className="offcanvas-header bg-secondary">
                              <h5
                                className="offcanvas-title"
                                id="offcanvasAddRackLabel"
                              >
                                Add Rack
                              </h5>
                              <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form className="row p-7">
                              <div className="col-12 col-md-6 mb-5 fv-row">
                                <label
                                  htmlFor="firstname"
                                  className="required form-label mb-3"
                                >
                                  Row No
                                </label>
                                <input
                                  required
                                  type="text"
                                  className="form-control form-control-sm form-control-solid"
                                  placeholder=""
                                  id="firstname"
                                  name="row_no"
                                  value={details.row_no}
                                  onChange={(e) => {
                                    setDetails((prev) => ({
                                      ...prev,
                                      [e.target.name]: e.target.value,
                                    }));
                                  }}
                                />
                              </div>

                              <div className="col-12 col-md-6 mb-5 fv-row">
                                <label
                                  htmlFor="lastname"
                                  className="required form-label mb-3"
                                >
                                  Rack Name
                                </label>
                                <input
                                  required
                                  type="text"
                                  className="form-control form-control-sm form-control-solid"
                                  placeholder=""
                                  id="lastname"
                                  name="rack_name"
                                  value={details.rack_name}
                                  onChange={(e) => {
                                    setDetails((prev) => ({
                                      ...prev,
                                      [e.target.name]: e.target.value,
                                    }));
                                  }}
                                />
                              </div>

                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleAddRack}
                                >
                                  Save
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger ms-2"
                                  data-bs-dismiss="offcanvas"
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>

                          {/* OFFCANVAS FOR EDIt RACK  */}
                          <div
                            className="offcanvas offcanvas-end"
                            tabIndex="-1"
                            id="offcanvasEdit"
                            aria-labelledby="offcanvasExampleLabel"
                            style={{ width: "500px" }}
                          >
                            <div className="offcanvas-header bg-secondary">
                              <h5
                                className="offcanvas-title"
                                id="offcanvasEdit"
                              >
                                Edit Rack
                              </h5>
                              <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form className="row p-7">
                              <div className="col-12 col-md-6 mb-5 fv-row">
                                <label
                                  htmlFor="firstname"
                                  className="required form-label mb-3"
                                >
                                  Row No
                                </label>
                                <input
                                  required
                                  type="text"
                                  className="form-control form-control-sm form-control-solid"
                                  placeholder=""
                                  id="firstname"
                                  name="Row"
                                  value={RacksRowdata.Row || ""}
                                  onChange={(e) => {
                                    setRacksRowdata((prev) => ({
                                      ...prev,
                                      [e.target.name]: e.target.value,
                                    }));
                                  }}
                                />
                              </div>

                              <div className="col-12 col-md-6 mb-5 fv-row">
                                <label
                                  htmlFor="lastname"
                                  className="required form-label mb-3"
                                >
                                  Rack Name
                                </label>
                                <input
                                  required
                                  type="text"
                                  className="form-control form-control-sm form-control-solid"
                                  placeholder=""
                                  id="lastname"
                                  name="Name"
                                  value={RacksRowdata.Name || ""}
                                  onChange={(e) => {
                                    setRacksRowdata((prev) => ({
                                      ...prev,
                                      [e.target.name]: e.target.value,
                                    }));
                                  }}
                                />
                              </div>

                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleEditRackItem}
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger ms-2"
                                  data-bs-dismiss="offcanvas"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body  pt-0">
                      <div className="table-responsive d-flex flex-row">
                        {/* Racks TABLE  */}
                        <table
                          className="table  table-row-dashed fs-6 gy-5  "
                          id="kt_customers_table"
                        >
                          <thead className="mx-2">
                            <tr className="text-start  fw-bold fs-7 text-uppercase gs-0 tableheader">
                              <th className="text-start min-w-70px px-5">
                                Actions
                              </th>
                              <th className="min-w-125px">ROW NO</th>
                              <th className="min-w-125px">RACK NAME</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600 gs-0 ">
                            {getRacksData.map((item) => (
                              <tr key={item.Id}>
                                <td className="text-start px-5">
                                  <button
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    className="btn btn-sm btn-primary btn-flex btn-center btn-active-light-primary"
                                    style={{
                                      backgroundColor: "#8096e0",
                                    }}
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                  >
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </button>
                                  <div className="dropdown mt-3">
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasEdit"
                                          aria-controls="offcanvasEdit"
                                          onClick={() => handleEditRacK(item)}
                                        >
                                          Edit
                                        </button>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => handleAssignRacK(item)}
                                          href="#assignbook"
                                        >
                                          Assign
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <a className="text-gray-800 text-hover-primary mb-1">
                                    {item.Row}
                                  </a>
                                </td>
                                <td>
                                  <a className="text-gray-600 text-hover-primary mb-1">
                                    {item.Name}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr className="m-0" />
                  {/* Assign Books To Rack  */}
                  {isShow && (
                    <div className="py-5" id="assignbook">
                      <div className="card-header row border-0 ">
                        <div className="card-title">
                          <div className="d-flex align-items-center position-relative my-1 mx-3">
                            Assign Books to Rack
                          </div>
                          <span className="text-gray-500 fw-bold ">
                            {RacksRowdata.Name}
                          </span>
                        </div>
                        <div className="card-toolbar">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-customer-table-toolbar="base"
                          >
                            <div className="card-header border-0 px-0">
                              <div className="card-title row ">
                                <div className="col-5 col-md-3 d-flex flex-column dropdown mt-3 ">
                                  <label
                                    htmlFor="category"
                                    className="required form-label mb-3"
                                  >
                                    Cateogry
                                  </label>
                                  <Select
                                    // style={{ width: 180 }}
                                    className="w-120px w-lg-200px"
                                    placeholder="Choose Cateogry"
                                    id="category"
                                    value={searchBook.category || null}
                                    onChange={(value) => {
                                      setSearchBook((prev) => ({
                                        ...prev,
                                        category: value,
                                      }));
                                    }}
                                    options={courseOptions}
                                  />
                                </div>

                                <div className="col-5 col-md-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                                  <label
                                    className="fs-6 fw-semibold mt-2 mb-3"
                                    htmlFor="booktype"
                                  >
                                    Book Type
                                  </label>
                                  <Select
                                    // showSearch
                                    // style={{ width: 180 }}
                                    className="w-120px w-lg-200px"
                                    placeholder="Choose Type"
                                    value={searchBook.book_type || null}
                                    onChange={(value) => {
                                      setSearchBook((prev) => ({
                                        ...prev,
                                        book_type: value,
                                      }));
                                    }}
                                    options={typeOptions}
                                  />
                                </div>

                                <div className="col-6 col-md-3 d-flex flex-column align-items-start position-relative my-1 mx-1">
                                  {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i> */}
                                  <label
                                    className="required fs-6 fw-semibold mt-2 mb-3"
                                    htmlFor="bookname"
                                  >
                                    Book Name/Author
                                  </label>
                                  <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                    <input
                                      type="text"
                                      id="bookname"
                                      data-kt-customer-table-filter="search"
                                      className="form-control form-control-solid w-180px ps-13"
                                      placeholder="Search Book "
                                      name="search_book"
                                      value={searchBook.search_book}
                                      onChange={(e) => {
                                        setSearchBook((prev) => ({
                                          ...prev,
                                          [e.target.name]: e.target.value,
                                        }));
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 mt-9 mx-2 w-100px">
                                  <button
                                    type="button"
                                    className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                                    onClick={handleSearch}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {loading && (
                        <div className="d-flex justify-content-center ">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                      {successMsg && (
                        <p className="text-gray-500 text-center">
                          {successMsg}
                        </p>
                      )}
                      <div className="card-body  pt-0">
                        <div className="table-responsive d-flex flex-row">
                          {/* Books TABLE  */}

                          <table
                            className="table  table-row-dashed fs-6 gy-5 "
                            id="kt_customers_table"
                          >
                            <thead className="mx-2">
                              <tr className="text-start fw-bold fs-7 text-uppercase gs-0 tableheader">
                                <th className="text-start min-w-70px px-5">
                                  Actions
                                </th>
                                <th className="min-w-125px">SL NO</th>
                                <th className="min-w-125px">NAME</th>
                                <th className="min-w-125px">Label</th>
                                <th className="min-w-125px">Author</th>
                              </tr>
                            </thead>

                            <tbody className="fw-semibold text-gray-600 gs-0 ">
                              {getData.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="form-check px-5 form-check-sm form-check-custom form-check-solid d-flex justify-content-center align-end">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={(e) => handleChange(e, item)}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a className="text-gray-600 text-hover-primary mb-1">
                                      {index + 1}
                                    </a>
                                  </td>
                                  <td>
                                    <a className="text-gray-600 text-hover-primary mb-1">
                                      {item.BookName}
                                    </a>
                                  </td>
                                  <td>{item.LabelName}</td>
                                  <td>{item.Author}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Racks;
